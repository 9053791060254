import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { HashLink as Link } from 'react-router-hash-link';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Container from '../../components/Container';
import NewsCarousel from '../../components/NewsCarousel';
import Partners from '../../components/Partners';
import Line from '../../components/Line';
import premiumCardLogo from './img/products/premium-icon-min.png';
import suppliesCardLogo from './img/products/supplies-icon-min.png';
import classicCardLogo from './img/products/classic-icon-min.png';
import diagnosticsCardLogo from './img/products/diagnostics-icon-min.png';

import premium from './img/services-logos/Briefcase-min.png';

import g1 from '../../pages/Homepage/img/top-section/top/g1.jpg';
import g2 from '../../pages/Homepage/img/top-section/top/g2.jpg';
import g3 from '../../pages/Homepage/img/top-section/top/g3.jpg';
import g4 from '../../pages/Homepage/img/top-section/top/g4.jpg';

import m1 from '../../pages/Homepage/img/top-section/medium/g1.jpg';
import m2 from '../../pages/Homepage/img/top-section/medium/g2.jpg';
import m3 from '../../pages/Homepage/img/top-section/medium/g3.jpg';
import m4 from '../../pages/Homepage/img/top-section/medium/g4.jpg';

import shape1 from './img/services-logos/service-1/Shape-1.png';
import shape2 from './img/services-logos/service-1/Shape-2.png';
import shape3 from './img/services-logos/service-1/Shape-3.png';
import shape4 from './img/services-logos/service-2/Shape-4.png';
import shape5 from './img/services-logos/service-2/Shape-5.png';
import circle from '../../img/center-circle.png';

import premiumLogo from './img/products/premium.png';
import standardLogo from './img/products/standard.png';
import partsLogo from './img/products/parts.png';
import reparationLogo from './img/products/service.png';
import agreement from './img/business-agreement.jpg';

import './index.sass';

const height = window.innerHeight;

const isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

class Homepage extends Component {
    state = {
        curtain: false,
    };

    flip = event => {
        const cardId = event.currentTarget.dataset.id;
        this.setState(state => ({ [cardId]: !state[cardId] }));
    };

    render() {
        return (
            <Fragment>
                <div className="top-section">
                    <Carousel
                        autoPlay={true}
                        infiniteLoop
                        axis={'horizontal'}
                        showThumbs={false}
                        showStatus={false}
                        showArrows={false}
                        showIndicators={false}
                        transitionTime={1000}
                        interval={4000}
                    >
                        <div>
                            <img src={height < 1367 ? m1 : g1} alt="" />
                        </div>
                        <div>
                            <img src={height < 1367 ? m2 : g2} alt="" />
                        </div>
                        <div>
                            <img src={height < 1367 ? m3 : g3} alt="" />
                        </div>
                        <div>
                            <img src={height < 1367 ? m4 : g4} alt="" />
                        </div>
                    </Carousel>
                </div>
                <section className="section-about">
                    <Line color="white" />
                    <Container
                        height="500px"
                        text={
                            <FormattedMessage
                                id="Homepage.aboutText"
                                defaultMessage="About us"
                            />
                        }
                        title="Homepage.about"
                        color="white"
                    >
                        <div className="read-more">
                            <Link smooth to="/about-us/#history-top-section">
                                <FormattedMessage
                                    id="Homepage.readMore"
                                    defaultMessage="Read more"
                                />
                            </Link>
                        </div>
                    </Container>
                    <div className="logos">
                        <div className="bg-logo" />
                        <div className="bg-logo2" />
                    </div>
                </section>
                <section className="section-products">
                    <div id={isChrome ? 'cproducts' : 'products'} />
                    <Line color="orange" />
                    <Container
                        text={
                            <FormattedMessage
                                id="Homepage.products.text"
                                defaultMessage="Products"
                            />
                        }
                        title="Homepage.products"
                    >
                        <div className="card-holder">
                            <Link to="/products/premium">
                                <div className="card-1">
                                    <div>
                                        <FormattedMessage
                                            id="Cards.premium"
                                            defaultMessage="Premium Services"
                                        />
                                        <img
                                            src={premiumCardLogo}
                                            alt="premium-logo"
                                            width="125"
                                            height="125"
                                        />
                                        <div className="premium-logo">
                                            <img
                                                className="left-logo"
                                                src={premiumLogo}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/products/standard">
                                <div className="card-3">
                                    <div>
                                        <FormattedMessage
                                            id="Cards.standard"
                                            defaultMessage="Premium Services"
                                        />
                                        <img
                                            src={classicCardLogo}
                                            alt="classic-logo"
                                            width="125"
                                            height="125"
                                        />
                                        <div className="premium-logo">
                                            <img
                                                className="left-logo"
                                                src={standardLogo}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/parts">
                                <div className="card-2">
                                    <div>
                                        <FormattedMessage
                                            id="Homepage.supplies"
                                            defaultMessage="Supplies"
                                        />
                                        <img
                                            src={suppliesCardLogo}
                                            alt="supplies-logo"
                                            width="125"
                                            height="125"
                                        />
                                        <div className="premium-logo">
                                            <img
                                                className="left-logo"
                                                src={partsLogo}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/reparation">
                                <div className="card-4">
                                    <div className="repairs">
                                        <FormattedMessage
                                            id="Cards.service"
                                            defaultMessage="Repairs"
                                        />
                                        <img
                                            src={diagnosticsCardLogo}
                                            alt="diagnostics-logo"
                                            width="125"
                                            height="125"
                                        />
                                        <div className="premium-logo">
                                            <img
                                                className="left-logo"
                                                src={reparationLogo}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </Container>
                </section>
                <section className="services">
                    <div id={isChrome ? 'cservices' : 'services'} />
                    <div className="green-curtain">
                        <Line color="white" />
                        <Container
                            text={
                                <FormattedMessage
                                    id="Homepage.servicesText"
                                    defaultMessage="Services"
                                />
                            }
                            title="Homepage.services"
                            color="white"
                        >
                            <div className="service-cards">
                                <Link smooth to={'/services'} className="card1">
                                    <div className="briefcase">
                                        <img src={premium} alt="premium" />
                                        <img
                                            className="shape1"
                                            src={shape1}
                                            alt="premium"
                                        />
                                        <img
                                            className="shape2"
                                            src={shape2}
                                            alt="premium"
                                        />
                                        <img
                                            className="shape3"
                                            src={shape3}
                                            alt="premium"
                                        />
                                    </div>
                                    <h4>
                                        <FormattedMessage
                                            id="Homepage.servicesPremium"
                                            defaultMessage="Premium services"
                                        />
                                    </h4>
                                    <div className="separator" />
                                    <div>
                                        <FormattedMessage
                                            id="Homepage.servicesPremiumText"
                                            defaultMessage="Premium services"
                                        />
                                    </div>
                                </Link>
                                <Link smooth to={'/services'} className="card2">
                                    <div className="key-gear">
                                        <img
                                            className="shape4"
                                            src={shape4}
                                            alt="econom"
                                        />
                                        <img
                                            className="shape5"
                                            src={shape5}
                                            alt="econom"
                                        />
                                    </div>
                                    <h4>
                                        <FormattedMessage
                                            id="Homepage.servicesEconom"
                                            defaultMessage="Standard services"
                                        />
                                    </h4>
                                    <div className="separator" />
                                    <div>
                                        <FormattedMessage
                                            id="Homepage.servicesEconomText"
                                            defaultMessage="Standard services"
                                        />
                                    </div>
                                </Link>
                                <Link smooth to={'/services'} className="card3">
                                    <div className="image-wrap">
                                        <img
                                            className="circle_1"
                                            src={circle}
                                            alt=""
                                        />
                                        <img
                                            className="circle_2"
                                            src={circle}
                                            alt=""
                                        />
                                        <img
                                            className="circle_3"
                                            src={circle}
                                            alt=""
                                        />
                                        <img
                                            className="wheel_1"
                                            src={shape4}
                                            alt="premium"
                                        />
                                        <img
                                            className="wheel_2"
                                            src={shape4}
                                            alt="premium"
                                        />
                                        <img
                                            className="wheel_3"
                                            src={shape4}
                                            alt="premium"
                                        />
                                    </div>
                                    <h4>
                                        <FormattedMessage
                                            id="Homepage.services.multiBrand"
                                            defaultMessage="Multi-brand services"
                                        />
                                    </h4>
                                    <div className="separator" />
                                    <div>
                                        <FormattedMessage
                                            id="Homepage.servicesMultiBrandText"
                                            defaultMessage="Multi-brand services"
                                        />
                                    </div>
                                </Link>
                            </div>
                        </Container>
                    </div>
                </section>
                <section className="section-news">
                    <div id={isChrome ? 'cnewsblock' : 'newsblock'} />
                    <Line color="orange" />
                    <Container title="Homepage.news">
                        <NewsCarousel />
                    </Container>
                </section>
                <section className="section-funding">
                    <div id={isChrome ? 'cfunds' : 'funds'} />
                    <Line color="white" />
                    <Container
                        width="auto"
                        title="Homepage.funding"
                        type="flipped"
                        color="white"
                    >
                        <div className="finance">
                            <img
                                src={agreement}
                                alt=""
                                width="415"
                                height="300"
                            />
                            <FormattedMessage
                                id="Homepage.fundingText"
                                defaultMessage="Funding"
                            />
                        </div>
                    </Container>
                </section>
                <section className="section-partners">
                    <Line color="orange" />
                    <Container title="Homepage.partners">
                        <Partners />
                    </Container>
                    <div id="navigate" />
                </section>
            </Fragment>
        );
    }
}

export default Homepage;
