import React, { Component } from 'react';
import './index.sass';
import { FormattedMessage } from 'react-intl';
import Line from '../../components/Line';
import SectionTitle from '../../components/SectionTitle';
import partsBackground from '../Parts/img/parts-background.jpg';
import partsPremium from '../Parts/img/parts-premium.jpg';
import partsStandart from '../Parts/img/parts-standart.jpg';

class Parts extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="agromotor-parts">
                <img
                    src={partsBackground}
                    alt=""
                    className="background-photo-parts"
                />
                <div className="agromotor-parts-wrapper">
                    <div className="parts-1">
                        <Line color="gray" className="title-line" />
                        <SectionTitle
                            title="Parts.premium"
                            className="parts-header"
                            color="gray"
                        />
                        <div className="parts-premium">
                            <p className="text-parts">
                                <FormattedMessage
                                    id="Parts.premiumDescription"
                                    defaultMessage="Compania Agromotor, fiind unul dintre cei mai mari furnizori de piese de schimb pentru tehnică agricolă."
                                />
                            </p>
                        </div>
                    </div>
                    <div className="parts-gallery">
                        <img
                            src={partsPremium}
                            alt=""
                            className="premium-photo"
                        />
                        <img
                            src={partsStandart}
                            alt=""
                            className="standart-photo"
                        />
                    </div>
                    <div className="parts-2">
                        <Line color="gray" className="title-line" />
                        <SectionTitle
                            title="Parts.standart"
                            color="gray"
                            className="parts-header"
                        />
                        <div className="parts-standart">
                            <p className="text-parts">
                                <FormattedMessage
                                    id="Parts.standartDescription"
                                    defaultMessage="Compania Aprocomteh/Agromotor în cei peste 20 ani de activitate în domeniul vînzării pieselor de schimb, fiind atenți la necesitățile fermierilor."
                                />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Parts;
