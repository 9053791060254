import axios from 'axios';
import { LANGUAGES, PER_PAGE, ROOT_API } from '../constants';

class ApiService {
    /**
     * Download data from the specified URL.
     * Filter by included categories
     *
     * @async
     * @function getItemsByCategories(page, category, locale)
     * @param {number|string} page - the number of page.
     * @param {array|string} categories - number of categories.
     * @param {string} locale - lang id (ro, ru, en).
     * @returns {AxiosPromise<any>} The data from the URL.
     */
    getItemsByCategories = (page, categories, locale) =>
        axios.get(ROOT_API + 'posts', {
            params: {
                per_page: PER_PAGE,
                page: page,
                categories: categories,
                tags: LANGUAGES[locale],
            },
        });

    /**
     * Download data from the specified URL.
     * Filter by excluded categories
     *
     * @async
     * @function getItemsByCategories(page, category, locale)
     * @param {number|string} page - the number of page.
     * @param {array|string} categories - number of categories.
     * @param {string} locale - lang id (ro, ru, en).
     * @returns {AxiosPromise<any>} The data from the URL.
     */
    getItemsByExcludeCategories = (page, categories, locale) =>
        axios.get(ROOT_API + 'posts', {
            params: {
                per_page: PER_PAGE,
                page: page,
                categories_exclude: categories,
                tags: LANGUAGES[locale],
            },
        });

    /**
     * Download data item from the specified URL.
     *
     * @async
     * @function getItem(id)
     * @param {string} id - The item id.
     * @returns {AxiosPromise<any>} The data from the URL.
     */
    getItem = id => axios.get(ROOT_API + `posts/${id}`);
}

export default new ApiService();
