import React, { Component } from 'react';
import './index.sass';
import { FormattedMessage } from 'react-intl';
import Line from '../../components/Line';
import SectionTitle from '../../components/SectionTitle';
import reparationBackground from '../Reparation/img/reparation-background.jpg';
import reparationImg1 from '../Reparation/img/reapar1.jpg';
import reparationImg2 from '../Reparation/img/repar2.jpg';
import reparationImg3 from '../Reparation/img/repar3.jpg';

class Reparation extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className="agromotor-reparation">
                <img
                    src={reparationBackground}
                    alt=""
                    className="background-photo-reparation"
                />
                <div className="agromotor-reparation-wrapper">
                    <div className="reparation-services">
                        <Line color="brown" />
                        <SectionTitle
                            title="Reparation.services"
                            color="brown"
                        />
                        <div className="reparation-description">
                            <p className="text-reparation">
                                <FormattedMessage
                                    id="Reparation.description1"
                                    defaultMessage="Compania Agromotor fiind în strînsă legătură cu fermierii țări."
                                />
                            </p>
                        </div>
                        <div className="reparation-gallery">
                            <img
                                src={reparationImg1}
                                alt=""
                                className="reparation-photo1"
                            />
                            <img
                                src={reparationImg2}
                                alt=""
                                className="reparation-photo2"
                            />
                            <img
                                src={reparationImg3}
                                alt=""
                                className="reparation-photo3"
                            />
                        </div>
                        <div className="reparation-description">
                            <p className="text-reparation">
                                <FormattedMessage
                                    id="Reparation.description2"
                                    defaultMessage="Un Service Multibrand este o necesitate a fermierului, pe care AGROMOTOR SERVICE o poate asigura."
                                />
                            </p>
                            <ul className="text-reparation reparation-list">
                                <li>
                                    <FormattedMessage
                                        id="Reparation.list1"
                                        defaultMessage="Diagnostic electronic complex,"
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        id="Reparation.list2"
                                        defaultMessage="Reparații capitale,"
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        id="Reparation.list3"
                                        defaultMessage="Reparații motoare,"
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        id="Reparation.list4"
                                        defaultMessage="Reparații cutii de viteză,"
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        id="Reparation.list5"
                                        defaultMessage="Reparații punți față/spate,"
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        id="Reparation.list6"
                                        defaultMessage="Reparații sisteme electrice,"
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        id="Reparation.list7"
                                        defaultMessage="Reparații sisteme hidraulice,"
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        id="Reparation.list8"
                                        defaultMessage="Verificare, reparare sisteme de alimentare,"
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        id="Reparation.list9"
                                        defaultMessage="Reparăm reductoare, servomecanisme, regulatoare, pompe hidraulice, distribuitoare,"
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        id="Reparation.list10"
                                        defaultMessage="Verificări, lucrări de strungărie,"
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        id="Reparation.list11"
                                        defaultMessage="Alimentarea condiționerelor,"
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        id="Reparation.list12"
                                        defaultMessage="Reglarea, repararea balotierelor etc."
                                    />
                                </li>
                            </ul>
                            <p className="text-reparation welcome-reparation">
                                <FormattedMessage
                                    id="Reparation.guarantees"
                                    defaultMessage="Oferim garantie, calitate si operativitate."
                                />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Reparation;
