import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tracks from './tracks';
import Percents from './percents';

import topWave from './img/Mask.png';
import botWave from './img/Mask.png';
import tractor from './img/tractor.png';
import tireBig from './img/tire-big.png';
import tireSmall from './img/tire-small.png';

import styles from './index.module.sass';

class Preloader extends Component {
    render() {
        return (
            <div className={styles['preloader']}>
                <img className={styles['topWave']} src={topWave} alt="" />
                <div className={styles['group']}>
                    <Tracks />
                    <div className={styles['container']}>
                        <div className={styles['tractor-wrapper']}>
                            <div className={styles['tractor']}>
                                <img
                                    className={styles['body']}
                                    src={tractor}
                                    alt=""
                                />

                                <img
                                    className={styles['tire-big']}
                                    src={tireBig}
                                    alt=""
                                />
                                <img
                                    className={styles['tire-small']}
                                    src={tireSmall}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles['percentage-box']}>
                        <div className={styles['percentage']}>
                            <Percents
                                turnON
                                preloaderOver={this.props.preloaderOver}
                            />
                        </div>
                    </div>
                </div>
                <img className={styles['botWave']} src={botWave} alt="" />
            </div>
        );
    }
}

Preloader.propTypes = {
    preloaderOver: PropTypes.func,
};

export default Preloader;
