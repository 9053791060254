import React, { Component, Fragment } from 'react';
import SectionHeading from '../SectionTitle';
import Map from '../Map';
import Address from '../Address';
import Copyright from '../Copyright';
import Line from '../Line';
import Select from 'react-select';
import locations from '../Dots/fixtures';
import classnames from 'classnames';

import './index.sass';

class Footer extends Component {
    state = {
        activeLocation: locations[0],
    };

    componentDidMount() {
        let input = document.getElementById('react-select-2-input');
        input.setAttribute('readonly', 'true');
    }

    handleLocation = location => {
        this.setState({
            activeLocation: location,
        });
    };

    handleDropdownSelect = ({ value }) => {
        const activeLocation = locations.find(location => {
            return location.id === value;
        });
        this.setState({
            activeLocation,
        });
    };

    renderLeftList = () => {
        const { activeLocation } = this.state;
        return locations.slice(0, 7).map(location => (
            <li key={location.id}>
                <button
                    className={classnames({
                        selected: location.id === activeLocation.id,
                    })}
                    onClick={() => this.handleLocation(location)}
                    onMouseEnter={() => this.handleLocation(location)}
                >
                    <span>{location.name}</span>
                    <div className="arrow" />
                </button>
            </li>
        ));
    };

    renderRightList = () => {
        const { activeLocation } = this.state;
        return locations.slice(7, 13).map(location => (
            <li key={location.id}>
                <button
                    className={classnames({
                        selected: location.id === activeLocation.id,
                    })}
                    onClick={() => this.handleLocation(location)}
                    onMouseEnter={() => this.handleLocation(location)}
                >
                    <span>{location.name}</span>
                    <div className="arrow" />
                </button>
            </li>
        ));
    };

    render() {
        const { activeLocation } = this.state;
        return (
            <Fragment>
                <footer className="footer" id="footer">
                    <div className="container">
                        <Line color="orange" />
                        <SectionHeading title={'Footer.contacts'} />
                        <div className="city-dropdown-list">
                            <Select
                                className="basic"
                                placeholder="select city"
                                options={locations.map(location => {
                                    return {
                                        value: location.id,
                                        label: location.name,
                                    };
                                })}
                                onChange={this.handleDropdownSelect}
                                value={{
                                    value: activeLocation.id,
                                    label: activeLocation.name,
                                }}
                            />
                        </div>
                        <div className="main">
                            <div className="grid-wrap">
                                <div className="left-city-list">
                                    <ul>{this.renderLeftList()}</ul>
                                </div>
                                <Map
                                    locations={locations}
                                    active={activeLocation}
                                    onSelect={this.handleLocation}
                                />
                                <Address address={activeLocation.address} />
                                <div className="right-city-list">
                                    <ul>{this.renderRightList()}</ul>
                                </div>
                            </div>
                        </div>
                        <Copyright />
                    </div>
                </footer>
            </Fragment>
        );
    }
}

export default Footer;
