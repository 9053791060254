import React, { Component } from 'react';
import ProductsList from '../ProductsList';
import Backdrop from '../Backdrop';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NavHashLink as NavLink } from 'react-router-hash-link';

import styles from './index.module.sass';

const isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

class NavigationItems extends Component {
    state = {
        showProducts: false,
    };

    toggleProductsList = () => {
        this.setState({ showProducts: !this.state.showProducts });
    };

    onClick = () => {
        window.scrollTo(0, 0);
    };

    render() {
        const { showProducts } = this.state;
        return (
            <div className={styles['navigation-items']}>
                {showProducts ? (
                    <Backdrop
                        onClick={() => this.setState({ showProducts: false })}
                    />
                ) : null}
                <ul className={styles['nav']}>
                    <li className={styles['nav-item']}>
                        <NavLink smooth to="/" onClick={this.onClick}>
                            <FormattedMessage
                                id="Home"
                                defaultMessage="Homepage"
                            />
                        </NavLink>
                    </li>
                    <li className={`${styles['nav-item']}`}>
                        <button onClick={this.toggleProductsList}>
                            <FormattedMessage
                                id="Header.products"
                                defaultMessage="products"
                            />
                        </button>
                        {this.state.showProducts ? (
                            <div className={`${styles['triangle']}`} />
                        ) : null}
                    </li>
                    <li className={styles['nav-item']}>
                        <NavLink
                            smooth
                            activeClassName="selected"
                            to="/about-us"
                        >
                            <FormattedMessage
                                id="Homepage.about"
                                defaultMessage="despre noi"
                            />
                        </NavLink>
                    </li>
                    <li className={styles['nav-item']}>
                        <NavLink
                            smooth
                            activeClassName="selected"
                            to="/services"
                        >
                            <FormattedMessage
                                id="Header.services"
                                defaultMessage="services"
                            />
                        </NavLink>
                    </li>
                    <li className={styles['nav-item']}>
                        <NavLink
                            smooth
                            activeClassName="selected"
                            to={isChrome ? '/#cnewsblock' : '/#newsblock'}
                        >
                            <FormattedMessage
                                id="Header.news"
                                defaultMessage="news"
                            />
                        </NavLink>
                    </li>
                    <li className={styles['nav-item']}>
                        <NavLink
                            smooth
                            activeClassName="selected"
                            to={isChrome ? '/#cfunds' : '/#funds'}
                        >
                            <FormattedMessage
                                id="Header.funds"
                                defaultMessage="funds"
                            />
                        </NavLink>
                    </li>
                    <li className={styles['nav-item']}>
                        <NavLink
                            smooth
                            activeClassName="selected"
                            to="/#navigate"
                        >
                            <FormattedMessage
                                id="Header.contacts"
                                defaultMessage="contacts"
                            />
                        </NavLink>
                    </li>
                </ul>
                {showProducts && (
                    <ProductsList onClick={this.toggleProductsList} />
                )}
            </div>
        );
    }
}

NavigationItems.propTypes = {
    clicked: PropTypes.func,
};

export default NavigationItems;
