import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Sound from 'react-sound';
import engineStart from './deutz-fahr-sound.mp3';
import { PREVIEW_PICTURE_INDEX } from '../../constants';
import { parsGallery } from '../../utils';
import cx from 'classnames';

import shape8 from '../../pages/Homepage/img/services-logos/service-4/Shape-8-white.png';
import shape9 from '../../pages/Homepage/img/services-logos/service-4/Shape-9-white.png';
import key from './img/Key.png';
import oval from './img/Oval.png';

import './index.sass';

class ProductExcerpt extends Component {
    state = {
        clicked: false,
    };

    handleClickDeutz = event => {
        event.preventDefault();
        const { product } = this.props;
        this.setState({ clicked: true });
        const link = document.getElementById(`link-to-product-${product.id}`);
        setTimeout(() => {
            link.click();
        }, 2200);
    };

    handleClick = event => {
        event.preventDefault();
        const { product } = this.props;
        this.setState({ clicked: true });
        const link = document.getElementById(`link-to-product-${product.id}`);
        link.click();
    };

    render() {
        const { product } = this.props;
        return (
            <div className="preview-product">
                <Link
                    id={`link-to-product-${product.id}`}
                    to={`/product/${product.id}`}
                />
                {this.state.clicked ? (
                    <Sound
                        url={engineStart}
                        playStatus={Sound.status.PLAYING}
                        volume={30}
                        playbackRate={1.5}
                    />
                ) : null}
                <div
                    className={cx({
                        link: true,
                        show: product.categories.includes(8),
                    })}
                    onClick={
                        product.categories.includes(8)
                            ? this.handleClickDeutz
                            : this.handleClick
                    }
                >
                    <div
                        className={cx({
                            'key-gears': true,
                            rotate: this.state.clicked,
                        })}
                    />
                    <div
                        className={cx({
                            starter: true,
                            rotate: this.state.clicked,
                        })}
                    >
                        <img className="oval" src={oval} alt="" />
                        <img className="key" src={key} alt="" />
                    </div>
                    <div
                        className={cx({
                            gears: true,
                            rotate: this.state.clicked,
                        })}
                    >
                        <img className="shape11" src={shape8} alt="gear" />
                        <img className="shape12" src={shape9} alt="gear" />
                        <img className="shape13" src={shape9} alt="gear" />
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: parsGallery(product.content.rendered)[
                                PREVIEW_PICTURE_INDEX
                            ],
                        }}
                        style={{ height: '67%' }}
                    />
                    <div className="info">
                        <p className="brands-title">
                            {product.title.rendered.split('/')[0]}
                        </p>
                        <p className="brands-info">
                            {product.title.rendered.split('/')[1]}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

ProductExcerpt.propTypes = {
    product: PropTypes.shape({}),
};

export default ProductExcerpt;
