import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import Backdrop from '../Backdrop';
import LanguageSwitcher from '../LanguageSwitcher';

import styles from './index.module.sass';

const isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

class SideMenu extends Component {
    onClick = () => {
        window.scrollTo(0, 0);
    };

    render() {
        const { locale, setLanguage, isOpen, onClick } = this.props;
        return (
            <Fragment>
                <div
                    className={classnames({
                        [styles['side-menu']]: true,
                        [styles['open']]: isOpen,
                        [styles['close']]: !isOpen,
                    })}
                >
                    <ul className={styles['nav']}>
                        <li
                            className={`${styles['nav-item']}`}
                            onClick={this.onClick}
                        >
                            <NavLink smooth to="/">
                                <FormattedMessage
                                    id="Home"
                                    defaultMessage="Homepage"
                                />
                            </NavLink>
                        </li>
                        <li
                            className={`${styles['nav-item']}`}
                            onClick={onClick}
                        >
                            <NavLink
                                smooth
                                activeClassName="selected"
                                to={isChrome ? '/#cproducts' : '/#products'}
                            >
                                <FormattedMessage
                                    id="Header.products"
                                    defaultMessage="products"
                                />
                            </NavLink>
                        </li>
                        <li className={styles['nav-item']} onClick={onClick}>
                            <NavLink
                                smooth
                                activeClassName="selected"
                                to="/about-us/#history-top-section"
                            >
                                <FormattedMessage
                                    id="Homepage.about"
                                    defaultMessage="despre noi"
                                />
                            </NavLink>
                        </li>
                        <li className={styles['nav-item']} onClick={onClick}>
                            <NavLink
                                smooth
                                activeClassName="selected"
                                to="/services"
                            >
                                <FormattedMessage
                                    id="Header.services"
                                    defaultMessage="services"
                                />
                            </NavLink>
                        </li>
                        <li className={styles['nav-item']} onClick={onClick}>
                            <NavLink
                                smooth
                                activeClassName="selected"
                                to={isChrome ? '/#cnewsblock' : '/#newsblock'}
                            >
                                <FormattedMessage
                                    id="Header.news"
                                    defaultMessage="news"
                                />
                            </NavLink>
                        </li>
                        <li className={styles['nav-item']} onClick={onClick}>
                            <NavLink
                                smooth
                                activeClassName="selected"
                                to={isChrome ? '/#cfunds' : '/#funds'}
                            >
                                <FormattedMessage
                                    id="Header.funds"
                                    defaultMessage="funds"
                                />
                            </NavLink>
                        </li>
                        <li className={styles['nav-item']} onClick={onClick}>
                            <NavLink
                                smooth
                                activeClassName="selected"
                                to="/#footer"
                            >
                                <FormattedMessage
                                    id="Header.contacts"
                                    defaultMessage="contacts"
                                />
                            </NavLink>
                        </li>
                        <li className={styles['lang']}>
                            <LanguageSwitcher
                                locale={locale}
                                setLanguage={setLanguage}
                            />
                        </li>
                    </ul>
                </div>
                <Backdrop onClick={onClick} />
            </Fragment>
        );
    }
}

SideMenu.propTypes = {
    isOpen: PropTypes.bool,
    locale: PropTypes.string,
    setLanguage: PropTypes.func,
    onClick: PropTypes.func,
};

export default SideMenu;
