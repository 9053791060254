import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import saga from './saga';
import reducer from './reducer';
import history from '../history';

const sagaMiddleware = createSagaMiddleware();

const middleware = applyMiddleware(sagaMiddleware, routerMiddleware(history));

const store = createStore(reducer, composeWithDevTools(middleware));

sagaMiddleware.run(saga);

//dev only
window.store = store;

export default store;
