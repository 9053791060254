import React from 'react';
import Dots from '../Dots';
import map from '../../img/md-map-min.png';

export default function Map({ active, locations, onSelect }) {
    return (
        <div className="map">
            <img src={map} alt="Moldova, Republic of" />
            <div style={{ position: 'absolute', marginTop: '-83px' }}>
                <Dots
                    locations={locations}
                    active={active}
                    onSelect={onSelect}
                />
            </div>
        </div>
    );
}
