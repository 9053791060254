import React from 'react';
import { FormattedMessage } from 'react-intl';
import './index.sass';

export default function Copyright() {
    return (
        <div className="copyright">
            <div className="bottom-line" />
            <div className="copy">
                <small>
                    Agromotor &copy; {new Date().getFullYear()}.
                    <FormattedMessage
                        id="Copyright.rights"
                        defaultMessage="All rights reserved."
                    />
                </small>
            </div>
        </div>
    );
}
