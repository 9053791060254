import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.sass';

/**
 * React Component.
 * Render table.
 * @class SpecificationsTable
 */
class SpecificationsTable extends Component {
    /**
     * @public
     * @returns {*}
     */
    render() {
        const { product } = this.props;
        return (
            <div className="table">
                <div dangerouslySetInnerHTML={{ __html: product }} />
            </div>
        );
    }
}

/**
 * @static
 * @type {{product: (shim|*|e|n)}}
 */
SpecificationsTable.propTypes = {
    product: PropTypes.string.isRequired,
};

export default SpecificationsTable;
