import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { EN, RO, RU } from '../../constants';

import styles from './index.module.sass';

/**
 * @class LanguageSwitcher
 */
class LanguageSwitcher extends Component {
    render() {
        const { locale } = this.props;
        return (
            <ul className={styles.lang}>
                <li
                    className={classnames({
                        [styles['lang-item']]: true,
                        [styles.present]: locale === RO,
                    })}
                >
                    <button onClick={() => this.props.setLanguage(RO)}>
                        ro
                    </button>
                </li>
                <li
                    className={classnames({
                        [styles['lang-item']]: true,
                        [styles.present]: locale === EN,
                    })}
                >
                    <button onClick={() => this.props.setLanguage(EN)}>
                        en
                    </button>
                </li>
                <li
                    className={classnames({
                        [styles['lang-item']]: true,
                        [styles['present']]: locale === RU,
                    })}
                >
                    <button onClick={() => this.props.setLanguage(RU)}>
                        ru
                    </button>
                </li>
            </ul>
        );
    }
}

/**
 * @static
 * @type {{locale: (shim|*|e|n), setLanguage: (shim|*|e|n)}}
 */
LanguageSwitcher.propTypes = {
    setLanguage: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
};

export default LanguageSwitcher;
