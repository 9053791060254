import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.sass';

/**
 * React component Description.
 * Embed HTML from Services
 * @class
 */
class Description extends Component {
    /**
     *
     * @returns {*}
     */
    render() {
        const { product } = this.props;
        return (
            <div
                className="description-content"
                dangerouslySetInnerHTML={{ __html: product }}
            />
        );
    }
}

/**
 *
 * @type {{product: (shim|*|e|n)}}
 */
Description.propTypes = {
    product: PropTypes.string.isRequired,
};

export default Description;
