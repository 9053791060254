import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavHashLink as NavLink } from 'react-router-hash-link';

import NavigationItems from '../NavigationItems';
import SideMenu from '../SideMenu';
import LanguageSwitcher from '../LanguageSwitcher';
import Hamburger from '../Hamburger';
import logo from '../../img/3e.png';

import styles from './index.module.sass';

/**
 * React Component.
 * @class Header
 */
class Header extends Component {
    /**
     * state
     * @type {{showProducts: boolean, isOpen: boolean}}
     */
    state = {
        showProducts: false,
        isOpen: false,
    };
    /**
     * @function
     * @public
     */
    onClick = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    scrollUp = () => {
        window.scrollTo(0, 0);
    };

    /**
     *
     * @returns {*}
     */
    render() {
        return (
            <div className={styles.header}>
                <div className={styles.logo}>
                    <NavLink smooth to={'/'}>
                        <img
                            alt="logo"
                            src={logo}
                            width="201"
                            onClick={this.scrollUp}
                        />
                    </NavLink>
                </div>
                <NavigationItems />
                <button onClick={this.onClick} className={styles.hamburger}>
                    <Hamburger />
                </button>
                {this.state.isOpen ? (
                    <SideMenu
                        setLanguage={this.props.setLanguage}
                        locale={this.props.locale}
                        isOpen={this.state.isOpen}
                        onClick={this.onClick}
                    />
                ) : null}
                <LanguageSwitcher
                    setLanguage={this.props.setLanguage}
                    locale={this.props.locale}
                />
            </div>
        );
    }
}

/**
 * @static
 * @type {{isOpen: shim, locale: (shim|*|e|n), setLanguage: (shim|*|e|n)}}
 */
Header.propTypes = {
    setLanguage: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
};

export default Header;
