import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Api from '../../services';
import SpecificationsTable from '../../components/SpecificationsTable';
import Gallery from '../../components/Gallery';
import { parsImg } from '../../utils';
import { BRANDS, CATEGORIES } from '../../constants';
import BrandRepresent from '../../components/BrandRepresent';
import './index.sass';
import Line from '../../components/Line';

/**
 * React Component.
 * Render Product.
 * @class
 */
class Product extends Component {
    /**
     * State
     * @public
     * @type {{product: string, error: null|any, title: string}}
     */
    state = {
        product: '',
        error: null,
        title: '',
        brand: '',
    };

    /**
     * Load Product from services
     * @returns {Promise<void | never>}
     */
    componentDidMount() {
        window.scrollTo(0, 0);
        const { id } = this.props.match.params;
        return this.props
            .fetchProduct(id)
            .then(res => {
                const brand = res.data.categories.find(category => {
                    return Object.keys(BRANDS).includes(`${category}`);
                });
                const category = res.data.categories.find(category => {
                    return Object.keys(CATEGORIES).includes(`${category}`);
                });
                return this.setState({
                    product: res.data.content.rendered,
                    title: res.data.title.rendered,
                    brand,
                    category,
                });
            })
            .catch(error => this.setState({ error }));
    }

    /**
     * Embed Product HTML from Services
     * @param product
     * @returns {*}
     */
    renderHeadImg = product => {
        return (
            product && (
                <div
                    className="head-image"
                    dangerouslySetInnerHTML={{ __html: parsImg(product) }}
                />
            )
        );
    };

    renderHeadingStyle = () => {
        return this.state.category === 4
            ? { color: '#5ebd57' }
            : { color: '#ff8718' };
    };

    /**
     * @public
     * @returns {*}
     */
    render() {
        const { product, title, brand } = this.state;
        const productTitle = title.split('/')[1];
        return (
            <div className="product-detail">
                <div style={{ display: 'flex' }}>
                    <BrandRepresent brand={brand} />
                </div>
                <Line color={this.state.category === 4 ? 'green' : 'orange'} />
                <h3
                    style={this.renderHeadingStyle()}
                    className="test-product-title"
                >
                    {productTitle}
                </h3>
                <div className="white-container">
                    <Gallery product={product} />
                </div>
                <div className="gray-container">
                    <SpecificationsTable
                        className="spec-table"
                        product={product}
                    />
                </div>
            </div>
        );
    }
}

/**
 * @static
 * @type {{fetchProduct: getItem}}
 */
Product.defaultProps = {
    fetchProduct: Api.getItem,
};

/**
 * @static
 * @type {{fetchProduct: shim, match: shim}}
 */
Product.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }),
    fetchProduct: PropTypes.func,
};

export default Product;
