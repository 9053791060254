import {
    takeEvery,
    call,
    put,
    all,
    takeLatest,
    select,
} from 'redux-saga/effects';
import { Record, OrderedMap } from 'immutable';
import { createSelector } from 'reselect';
import Api from '../services';
import { arrToMap } from './utils';
import { CLASSIC, PREMIUM, WP_TOTAL_PAGES } from '../constants';
import { BRANDS, TYPES, NEWS_INDEX, CATEGORIES_IDS } from '../constants';

const categoryList = [
    ...Object.keys(BRANDS),
    ...Object.keys(TYPES),
    NEWS_INDEX,
];
/**
 * Constants
 * */
export const moduleName = 'products';
const prefix = `agromotor/${moduleName}`;

export const SUCCESS = '/SUCCESS';
export const REJECT = '/REJECT';
export const IN_PROGRESS = '/IN_PROGRESS';
export const FETCH_PRODUCTS = `${prefix}/FETCH_PRODUCTS`;
export const CHANGE_FILTER_BRANDS = `${prefix}/CHANGE_FILTER_BRANDS`;
export const CHANGE_FILTER_TYPES = `${prefix}/CHANGE_FILTER_TYPES`;
export const CHANGE_PAGE = `${prefix}/CHANGE_PAGE`;
export const CLEAR_PRODUCTS = `${prefix}/CLEAR_PRODUCTS`;
export const FETCH_ALL = `${prefix}/FETCH_ALL`;

/**
 * Selectors
 */
// todo add Selector suffix to each selector name
export const stateSelector = state => state[moduleName];
export const getFilterBrands = createSelector(
    stateSelector,
    state => state.filterBrands
);
export const getFilterTypes = createSelector(
    stateSelector,
    state => state.filterTypes
);
export const getTotalPages = createSelector(
    stateSelector,
    state => state.totalPages
);

export const getPage = createSelector(
    stateSelector,
    state => state.page
);

export const productsList = createSelector(
    stateSelector,
    state => state.entities.toArray().map(p => p[1])
);

export const excludeCategory = createSelector(
    stateSelector,
    state => state.excludeCategory
);

export function categorySelector(state) {
    const { hash = '' } = state.router.location;
    if (hash.includes(CLASSIC)) {
        return CLASSIC;
    } else if (hash.includes(PREMIUM)) {
        return PREMIUM;
    }
    return undefined;
}

/**
 * Reducer
 * */
export const ReducerRecord = Record({
    filterBrands: [],
    filterTypes: [],
    excludeCategory: 0,
    page: 1,
    totalPages: WP_TOTAL_PAGES,
    error: null,
    entities: new OrderedMap({}),
    ids: [],
});

export default function reducer(state = new ReducerRecord(), action) {
    const { type, payload, error } = action;

    switch (type) {
        case CHANGE_FILTER_BRANDS:
            return state
                .updateIn(['filterBrands'], value => {
                    if (value.includes(payload.id)) {
                        return value.filter(fill => fill !== payload.id);
                    }

                    return [...value, payload.id];
                })
                .set('excludeCategory', payload.category)
                .set('page', 1);

        case CHANGE_FILTER_TYPES:
            return state
                .updateIn(['filterTypes'], value => {
                    if (value.includes(payload.id)) {
                        return value.filter(fill => fill !== payload.id);
                    }

                    return [...value, payload.id];
                })
                .set('excludeCategory', payload.category);

        case CHANGE_PAGE:
            return state.set('page', payload.page);

        case FETCH_PRODUCTS + SUCCESS:
            return state
                .set('entities', arrToMap(payload.products))
                .set('totalPages', payload.totalPages);

        case FETCH_PRODUCTS + REJECT:
            return state.set('error', error);

        case FETCH_ALL:
            return new ReducerRecord();

        default:
            return state;
    }
}

/**
 * Action Creators
 * */
export function setFilterBrands(id, locale, category) {
    return {
        type: CHANGE_FILTER_BRANDS,
        payload: { id, locale, category },
    };
}
export function setFilterTypes(id, locale, category) {
    return {
        type: CHANGE_FILTER_TYPES,
        payload: { id, locale, category },
    };
}

export function setPage(page) {
    return {
        type: CHANGE_PAGE,
        payload: { page },
    };
}

export function clearProducts() {
    return {
        type: CLEAR_PRODUCTS,
    };
}

export function fetchAll(category, locale) {
    return {
        type: FETCH_ALL,
        payload: { category, locale },
    };
}

/**
 *  Sdas
 * @returns {IterableIterator<*>}
 */
// todo get locale from redux state instaed of passing it with function params
export function* fetchProductsSaga({ payload }) {
    try {
        let filter = [];
        let categories = [];
        const exclCategory = yield select(excludeCategory);
        const page = yield select(getPage);
        const filterBrands = yield select(getFilterBrands);
        const filterTypes = yield select(getFilterTypes);
        const category = yield select(categorySelector);
        // todo move all categories filatration into a function of its own
        if (filterBrands.length > 0 && filterTypes.length > 0) {
            filter = [...filterBrands, ...filterTypes];
            categories = categoryList.filter(
                category => !filter.includes(category)
            );
        } else if (filterBrands.length > 0 && filterTypes.length < 1) {
            filter = [...filterBrands, ...Object.keys(TYPES)];
            categories = categoryList.filter(
                category => !filter.includes(category)
            );
        } else if (filterBrands.length < 1 && filterTypes.length > 0) {
            filter = [...filterTypes, ...Object.keys(BRANDS)];
            categories = categoryList.filter(
                category => !filter.includes(category)
            );
        }

        if (filter.length > 0) {
            categories.push(exclCategory);
            const response = yield call(
                Api.getItemsByExcludeCategories,
                page,
                categories,
                payload.locale
            );
            yield put({
                // todo create another action FETCH_PRODUCTS_SUCCESS, concatenation in place is bad
                type: FETCH_PRODUCTS + SUCCESS,
                payload: {
                    products: response.data,
                    totalPages: +response.headers[WP_TOTAL_PAGES],
                },
            });
        } else {
            const response = yield call(
                Api.getItemsByCategories,
                page,
                CATEGORIES_IDS[category],
                payload.locale
            );
            yield put({
                type: FETCH_PRODUCTS + SUCCESS,
                payload: {
                    products: response.data,
                    totalPages: +response.headers[WP_TOTAL_PAGES],
                },
            });
        }
    } catch (e) {
        yield put({
            type: FETCH_PRODUCTS + REJECT,
            payload: null,
            error: e,
        });
    }
}

// todo merge this function and actions with preceding one, the code is almost identical
export function* fetchAllSaga({ payload }) {
    try {
        const page = yield select(getPage);
        const category = yield select(categorySelector);
        const response = yield call(
            Api.getItemsByCategories,
            page,
            CATEGORIES_IDS[category],
            payload.locale
        );
        yield put({
            type: FETCH_PRODUCTS + SUCCESS,
            payload: {
                products: response.data,
                totalPages: +response.headers[WP_TOTAL_PAGES],
            },
        });
    } catch (e) {
        yield put({
            type: FETCH_ALL + REJECT,
            payload: null,
            error: e,
        });
    }
}

export function* saga() {
    yield all([
        takeLatest(CHANGE_FILTER_BRANDS, fetchProductsSaga),
        takeLatest(CHANGE_FILTER_TYPES, fetchProductsSaga),
        takeEvery(CHANGE_PAGE, fetchProductsSaga),
        takeEvery(FETCH_ALL, fetchAllSaga),
    ]);
}
