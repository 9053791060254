// Locale
/**
 * @constant
 * @type {string}
 */
export const EN = 'en';
/**
 * @constant
 * @type {string}
 */
export const RO = 'ro';
/**
 * @constant
 * @type {string}
 */
export const RU = 'ru';

// Root Api
/**
 * @constant
 * @type {string}
 */
export const ROOT_API =
    process.env.REACT_APP_DEV_API || 'https://api.agromotor.md/wp-json/wp/v2/';

// tags id
/**
 * @constant
 * @type {{ru: number, en: number, ro: number}}
 */
export const LANGUAGES = {
    en: 33,
    ro: 31,
    ru: 32,
};

/**
 * @constant
 * @type {number}
 */
export const PER_PAGE = 12;
/**
 * @constant
 * @type {number}
 */
export const START_PAGE = 1;
/**
 * @constant
 * @type {{"11": string, "22": string, "12": string, "23": string, "13": string, "24": string, "14": string, "25": string, "15": string, "26": string, "16": string, "27": string, "17": string, "28": string, "18": string, "29": string, "19": string, "8": string, "9": string, "30": string, "20": string, "10": string, "21": string}}
 */
export const BRANDS = {
    8: 'Deutz Fahr',
    9: 'Amazone',
    10: 'Sola',
    11: 'Sicma',
    12: 'Fantini',
    13: 'AV Map',
    14: 'Ciccoria',
    15: 'Quivogne',
    16: 'Belnshina',
    17: 'Triangle',
    18: 'Altai',
    19: 'Oils',
    20: 'Antifreeze',
    21: 'Battery',
    22: 'Dong-Feng',
    23: 'Turan',
    // 24: 'Lusna',
    25: 'Ozdoken',
    // 26: 'Abollo',
    27: 'Ucbasak',
    28: 'Hartehprom',
    29: 'Umanifermash',
    30: 'Agroremmash',
    48: 'Wirax',
    53: 'Untar',
    54: 'Surmak',
};

export const TYPES = {
    35: 'tractoare',
    36: 'pluguri',
    37: 'grape',
    38: 'stropitori',
    39: 'freze',
    40: 'semanatori',
    41: 'combine',
    42: 'distribuitoare',
    43: 'tocatoare',
    44: 'balotiere',
    45: 'seceratori',
    46: 'scarificatoare',
    47: 'cositori',
    49: 'cultivatoare',
    50: 'introducatoare de ingrasaminte',
    51: 'greble',
    52: 'incarcatoare',
};

export const CATEGORIES = {
    4: 'premium',
    6: 'standard',
};

export const CATEGORIES_IDS = {
    premium: 4,
    standard: 6,
};
/**
 * @constant
 * @type {string}
 */
export const PREMIUM = 'premium';
/**
 * @constant
 * @type {string}
 */
export const SUPPLIES = 'supplies';
/**
 * @constant
 * @type {string}
 */
export const CLASSIC = 'standard';
/**
 * @constant
 * @type {string}
 */
export const DIAGNOSTICS = 'diagnostics';
/**
 * @constant
 * @type {number}
 */
export const CLASSIC_INDEX = 6;
/**
 * @constant
 * @type {number}
 */
export const NEWS_INDEX = 7;
/**
 * @constant
 * @type {number}
 */
export const PREMIUM_INDEX = 4;
/**
 * @constant
 * @type {number}
 */
export const SUPPLIES_INDEX = 5;
/**
 * @constant
 * @type {number}
 */
export const PREVIEW_PICTURE_INDEX = 2;

// WP
/**
 * Response header.
 * @constant
 * @type {string}
 */
export const WP_TOTAL_PAGES = 'x-wp-totalpages';
