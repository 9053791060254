// Categories
/**
 * @constant
 * @type {number}
 */
export const DEUTZ_FAHR = 8;
/**
 * @constant
 * @type {number}
 */
export const AMAZONE = 9;
/**
 * @constant
 * @type {number}
 */
export const SOLA = 10;
/**
 * @constant
 * @type {number}
 */
export const SICMA = 11;
/**
 * @constant
 * @type {number}
 */
export const FANTINI = 12;
/**
 * @constant
 * @type {number}
 */
export const AV_MAP = 13;
/**
 * @constant
 * @type {number}
 */
export const CICCORIA = 14;
/**
 * @constant
 * @type {number}
 */
export const QUIVONGE = 15;
/**
 * @constant
 * @type {number}
 */
export const BELSHINA = 16;
/**
 * @constant
 * @type {number}
 */
export const TRIANGLE = 17;
/**
 * @constant
 * @type {number}
 */
export const ALTAI = 18;
/**
 * @constant
 * @type {number}
 */
export const OILS = 19;
/**
 * @constant
 * @type {number}
 */
export const ANTIFREEZE = 20;
/**
 * @constant
 * @type {number}
 */
export const BATTARY = 21;
/**
 * @constant
 * @type {number}
 */
export const DONG_FENG = 22;
/**
 * @constant
 * @type {number}
 */
export const TURAN = 23;
/**
 * @constant
 * @type {number}
 */
export const LUSNA = 24;
/**
 * @constant
 * @type {number}
 */
export const OZDOKEN = 25;
/**
 * @constant
 * @type {number}
 */
export const ABOLLO = 26;
/**
 * @constant
 * @type {number}
 */
export const UCBASAK = 27;
/**
 * @constant
 * @type {number}
 */
export const HARTEHPROM = 28;
/**
 * @constant
 * @type {number}
 */
export const UMANIFERMASH = 29;
/**
 * @constant
 * @type {number}
 */
export const AGROREMASH = 30;
/**
 * @constant
 * @type {number}
 */
export const WIRAX = 48;
/**
 * @constant
 * @type {number}
 */
export const UNTAR = 53;
/**
 * @constant
 * @type {number}
 */
export const SURMAK = 54;
