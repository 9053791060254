import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { parsGallery } from '../../utils';
import Modal from 'react-responsive-modal';

import './index.sass';

/**
 * React Component.
 * Render Gallery.
 * @class Gallery
 */
class Gallery extends Component {
    /**
     * state
     * @type {{index: null, open: boolean}}
     */
    state = {
        open: false,
        index: null,
    };
    /**
     * @function onOpenModal
     * @public
     * @param index
     */
    onOpenModal = index => {
        this.setState({ open: true, index });
    };
    /**
     * @function onCloseModal
     * @public
     */
    onCloseModal = () => {
        this.setState({ open: false });
    };
    /**
     * @function renderImages
     * @public
     */
    renderImages = () => {
        const { product } = this.props;
        const { open, index } = this.state;
        return (
            product &&
            parsGallery(product).map((image, i) => (
                <Fragment key={image}>
                    <button
                        className={`image-${i}`}
                        onClick={() => this.onOpenModal(i)}
                    >
                        <div dangerouslySetInnerHTML={{ __html: image }} />
                    </button>
                    <Modal
                        open={open}
                        onClose={this.onCloseModal}
                        classNames={{
                            overlay: 'customOverlay',
                            modal: 'customModal',
                        }}
                    >
                        <div
                            className="on-modal"
                            dangerouslySetInnerHTML={{
                                __html: parsGallery(product)[index],
                            }}
                        />
                    </Modal>
                </Fragment>
            ))
        );
    };

    /**
     *
     * @returns {*}
     */
    render() {
        return <div className="gallery">{this.renderImages()}</div>;
    }
}

/**
 * @static
 * @type {{product: (shim|*|e|n)}}
 */
Gallery.propTypes = {
    product: PropTypes.string.isRequired,
};

export default Gallery;
