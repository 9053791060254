import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '../SectionTitle';

import './index.sass';

class Container extends Component {
    render() {
        const style = {
            height: this.props.height,
            width: this.props.width,
        };
        return (
            <div className="section" style={style}>
                {this.props.title && (
                    <SectionTitle
                        title={this.props.title}
                        color={this.props.color}
                    />
                )}
                <div className="text">{this.props.text}</div>
                {this.props.children}
            </div>
        );
    }
}

Container.defaultProps = {
    title: '',
};

Container.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
    text: PropTypes.node,
    title: PropTypes.string,
    color: PropTypes.string,
};

export default Container;
