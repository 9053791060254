import { PREMIUM, SUPPLIES, CLASSIC, RO, RU, EN } from '../constants';
import { BELSHINA, QUIVONGE, DONG_FENG } from '../constants/brands';

/**
 * Pars HTML.
 *
 * @function parsImg(string)
 * @param {string} string - HTML.
 * @returns {string}  HTML <img src="http://url.com"/>
 */
export function parsImg(string) {
    return string.match(/<img([\w\W]+?)\/>/)[0];
}

/**
 * Pars HTML.
 *
 * @function parsGallery(string)
 * @param {string} string - HTML.
 * @returns {array} [<img src="http://url.com"/>]
 */
export function parsGallery(string) {
    return string.match(/<img([\w\W]+?)\/>/g);
}

/**
 * Get user language from navigator.
 *
 * @function getDefaultLang
 * @param {string|number} brand - The brand id.
 * @returns {string}  category name
 */
export function getBrandCategory(brand) {
    if (+brand < BELSHINA) {
        return PREMIUM;
    }
    if (+brand > QUIVONGE && +brand < DONG_FENG) {
        return SUPPLIES;
    }
    return CLASSIC;
}

/**
 * Wait.
 *
 * @async
 * @function waitFor(ms)
 * @param {number} ms - The number of milliseconds for wait.
 * @returns {Promise<any>}
 */
export const waitFor = ms => new Promise(r => setTimeout(r, ms));

/**
 * Async loop.
 *
 * @async
 * @function delay(arr, callback)
 * @param {array} arr - The item id.
 * @param callback callback - callback func which will call
 * @returns {Promise<void>} The data from the URL.
 */
export async function delay(arr, callback) {
    for (let index = 0; index < arr.length; index++) {
        await callback(arr[index], index, arr);
    }
}

/**
 * Get user language from navigator.
 *
 * @function getDefaultLang
 * @returns {string}  'ro' | 'ru' | 'en'
 */
export const getDefaultLang = () => {
    if (navigator.languages.includes(RO)) return RO;
    if (navigator.languages.includes(RU)) return RU;
    return EN;
};
