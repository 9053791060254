import React from 'react';
import styles from './index.module.sass';

export default function Hamburger() {
    return (
        <div className={styles['hamburger-wrapper']}>
            <div className={styles['hamburger-slice']} />
            <div className={styles['hamburger-slice']} />
            <div className={styles['hamburger-slice']} />
        </div>
    );
}
