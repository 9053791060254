import React from 'react';
import styles from './index.module.sass';
import tracks from './img/tracks-group.png';
import fill from 'lodash.fill';

const arrTracks = fill(Array(120), null);

const Tracks = () => {
    return (
        <div className="row" style={{ display: 'inherit' }}>
            {arrTracks.map((_, index) => (
                <div key={`${index}-track`} className={styles['track-wrapper']}>
                    <img className={styles['tracks']} src={tracks} alt="" />
                </div>
            ))}
        </div>
    );
};

export default Tracks;
