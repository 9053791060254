import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.sass';

const Backdrop = props => {
    return <div className={styles['backdrop']} onClick={props.onClick} />;
};

Backdrop.propTypes = {
    onClick: PropTypes.func,
};

export default Backdrop;
