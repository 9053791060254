import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PREMIUM } from '../../constants';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { HashLink as Link } from 'react-router-hash-link';

import styles from './index.module.sass';

const style = {
    colorSwitchBase: {
        color: '#5ebd57',
        '&$colorChecked': {
            color: '#5ebd57',
            '& + $colorBar': {
                backgroundColor: 'darkgray',
            },
        },
    },
    colorBar: {
        color: '#5ebd57',
    },
    colorChecked: {
        color: '#5ebd57',
        '&$colorChecked': {
            color: '#ff8718',
            '& + $colorBar': {
                backgroundColor: 'darkgray',
            },
        },
    },
};

class Switcher extends Component {
    state = {
        isStandard: false,
    };

    componentDidMount() {
        if (this.props.category === PREMIUM) {
            this.setState({ isStandard: false });
        } else {
            this.setState({ isStandard: true });
        }
    }

    setPremium = () => {
        this.setState({ isStandard: false });
    };

    setStandard = () => {
        this.setState({ isStandard: true });
    };

    render() {
        return (
            <div className={styles['switch-holder']}>
                <Link to="/products/premium" onClick={this.setPremium}>
                    <div
                        className={cx({
                            [styles['premium-option']]: true,
                            [styles['premium-option-active']]: !this.state
                                .isStandard,
                        })}
                    >
                        PREMIUM
                    </div>
                </Link>
                <Link to="/products/standard" onClick={this.setStandard}>
                    <div
                        className={cx({
                            [styles['classic-option']]: true,
                            [styles['classic-option-active']]: this.state
                                .isStandard,
                        })}
                    >
                        STANDARD
                    </div>
                </Link>
            </div>
        );
    }
}

Switcher.propTypes = {
    category: PropTypes.string.isRequired,
};

export default withStyles(style)(Switcher);
