import React, { Component } from 'react';
import classnames from 'classnames';
import { ORANGE, WHITE, GREEN, BROWN, GRAY } from '../../constants/colors';
import PropTypes from 'prop-types';

import './index.sass';

class Line extends Component {
    render() {
        return (
            <div
                className={classnames('separation-line', {
                    [this.props.color]: true,
                })}
            />
        );
    }
}

Line.defaultProps = {
    color: ORANGE,
};

Line.propTypes = {
    color: PropTypes.oneOf([ORANGE, WHITE, GREEN, BROWN, GRAY]),
};

export default Line;
