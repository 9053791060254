import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as brands from '../../constants/brands';
import deutz from './img/logos/deutz-fahr.png';
import amazone from './img/logos/amazone.png';
import sola from './img/logos/sola.png';
import sicma from './img/logos/sicma.png';
import fantini from './img/logos/fantini.png';
import avmap from './img/logos/av-map.png';
import ciccoria from './img/logos/cicoria.png';
import quivonge from './img/logos/quivogne.png';
import dfm from './img/logos/dfm.png';
import lusna from './img/logos/lusna.png';
import umanifermash from './img/logos/umanfermmash.png';
import ozdoken from './img/logos/ozdoken.png';
import turan from './img/logos/turan.png';
import abollo from './img/logos/abollo.png';
import ucbasak from './img/logos/ucbasak.png';
import sloboda from './img/logos/sloboda.png';
import agroremmash from './img/logos/agroremmash.png';
import wirax from './img/logos/wirax.png';
import untar from './img/logos/untar.png';
import surmak from './img/logos/surmak.png';
import NoLogo from '../../pages/Homepage/img/logos/NoLogo.png';

import './index.sass';

class BrandRepresent extends Component {
    renderBrand = () => {
        switch (+this.props.brand) {
            case brands.DEUTZ_FAHR:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={deutz} alt="" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Deutzfahr.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.AMAZONE:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={amazone} alt="" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Amazone.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.SOLA:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={sola} alt="" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="SOLA.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.SICMA:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={sicma} alt="" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Sicma.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.FANTINI:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={fantini} alt="" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Fantini.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.AV_MAP:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={avmap} alt="" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="AvMap.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.CICCORIA:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={ciccoria} alt="" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Cicoria.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.QUIVONGE:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={quivonge} alt="" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Quivogne.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.BELSHINA:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={NoLogo} alt="No Logo" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Brand.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.TRIANGLE:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={NoLogo} alt="No Logo" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Brand.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.ALTAI:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={NoLogo} alt="No Logo" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Brand.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.DONG_FENG:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={dfm} alt="" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Dfm.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.TURAN:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={turan} alt="" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Turan.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.LUSNA:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={lusna} alt="" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Lusna.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.OZDOKEN:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={ozdoken} alt="" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Ozdoken.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.ABOLLO:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={abollo} alt="" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Abollo.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.UCBASAK:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={ucbasak} alt="" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Ucbasak.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.HARTEHPROM:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={sloboda} alt="" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Hartehprom.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.UMANIFERMASH:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={umanifermash} alt="No Logo" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Umanifermash.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.AGROREMASH:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={agroremmash} alt="" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Agroremash.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.WIRAX:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={wirax} alt="" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Wirax.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.SURMAK:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={surmak} alt="" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Surmak.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            case brands.UNTAR:
                return (
                    <Fragment>
                        <div className="logo-wrap">
                            <img src={untar} alt="" />
                        </div>
                        <p>
                            <FormattedMessage
                                id="Untar.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
            default:
                return (
                    <Fragment>
                        <img src={NoLogo} alt="No Logo" />
                        <p>
                            <FormattedMessage
                                id="Brand.short.description"
                                defaultMessage="Brand Short Description"
                            />
                        </p>
                    </Fragment>
                );
        }
    };

    render() {
        return <div className="brand-description">{this.renderBrand()}</div>;
    }
}

BrandRepresent.propTypes = {
    brand: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default BrandRepresent;
