import React from 'react';
import cx from 'classnames';

import './index.sass';

export default function Dots({ locations, active, onSelect }) {
    return locations.map(location => (
        <div
            onClick={() => onSelect(location)}
            key={location.id}
            className={cx('circle', {
                [location.name]: true,
                select: location.id === active.id,
            })}
            style={{
                top: `${location.position.top}`,
                left: `${location.position.left}`,
            }}
        >
            <div className="bullzeye" />
        </div>
    ));
}
