import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Column } from 'simple-flexbox';
import Api from '../../services';
import Description from '../../components/Description';
import { parsImg } from '../../utils';
import NewsCarousel from '../../components/NewsCarousel';

import './index.sass';

/**
 * React Component.
 * Creates a new NewsPage.
 * Render News Item and News List
 * @class NewsPage.
 * @param {object} match - query string.
 * @param {function} fetchNews - fetch news.
 */
class NewsPage extends Component {
    /**
     * state field.
     * @public
     * @type {{date: string, news: string, error: null, title: string}}
     */
    state = {
        date: '',
        error: null,
        news: '',
        title: '',
    };

    /**
     * Fetch news and set data to this.state.
     * Set error on error
     *
     * @function componentDidMount
     * @public
     * @returns {Promise<T | void>}
     */
    componentDidMount() {
        window.scrollTo(0, 0);
        const { id } = this.props.match.params;
        return this.props
            .fetchNews(id)
            .then(res =>
                this.setState({
                    news: res.data.content.rendered,
                    title: res.data.title.rendered,
                    date: res.data.date,
                })
            )
            .catch(error => this.setState({ error }));
    }

    /**
     * Fetch news if id !== id
     *
     * @function componentDidUpdate(prevProps)
     * @param prevProps
     * @returns {Promise<T | void>}
     */
    componentDidUpdate(prevProps) {
        const { id } = this.props.match.params;
        if (prevProps.match.params.id !== id) {
            return this.props
                .fetchNews(id)
                .then(res =>
                    this.setState({
                        news: res.data.content.rendered,
                        title: res.data.title.rendered,
                        date: res.data.date,
                    })
                )
                .catch(error => this.setState({ error }));
        }
    }

    /**
     *Embed HTML from services
     *
     * @param news
     * @returns {*}
     */
    renderHeadImg = news => {
        return (
            news && (
                <div
                    className="head-image"
                    dangerouslySetInnerHTML={{ __html: parsImg(news) }}
                />
            )
        );
    };

    /**
     * Render HTML
     *
     * @public
     * @returns {*}
     */
    render() {
        const { news, title, date } = this.state;
        const day = moment(date).format('D MMMM YYYY');
        return (
            <div className="news-detail">
                <Column horizontal="center">
                    {this.renderHeadImg(news)}
                    <div className="news-head">
                        <Column horizontal="center">
                            <div className="line" />
                            <h3 className="test-news-title">{title}</h3>
                            <h4>
                                <FontAwesomeIcon icon={faClock} />
                                <time
                                    className="test-news-date"
                                    dateTime={date}
                                >
                                    {day}
                                </time>
                            </h4>
                        </Column>
                    </div>
                    <div className="news-content">
                        <Description product={news} />
                    </div>
                    <div className="green-line" />
                    <NewsCarousel />
                </Column>
            </div>
        );
    }
}

/**
 * @static
 * @type {{fetchNews: Api.getItem}}
 */
NewsPage.defaultProps = {
    fetchNews: Api.getItem,
};

/**
 * @static
 * @type {{fetchNews: shim, match: shim}}
 */
NewsPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }),
    fetchNews: PropTypes.func,
};

export default NewsPage;
