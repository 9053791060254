import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import deutz from '../../pages/Homepage/img/logos/deutz-fahr.svg';
import amazone from '../../pages/Homepage/img/logos/amazone.png';
import dfm from '../../pages/Homepage/img/logos/dfm.png';
import lusna from '../../pages/Homepage/img/logos/lusna.png';
import sicma from '../../pages/Homepage/img/logos/sicma.png';
import sola from '../../pages/Homepage/img/logos/sola.png';
import fantini from '../../pages/Homepage/img/logos/fantini.png';
import avmap from '../../pages/Homepage/img/logos/avmap.png';
import ozdoken from '../../pages/Homepage/img/logos/ozdoken.png';
import cicoria from '../../pages/Homepage/img/logos/cicoria.png';
import quivogne from '../../pages/Homepage/img/logos/quivogne.png';
import sloboda from '../../pages/Homepage/img/logos/sloboda.png';

import styles from './index.module.sass';

class Partners extends Component {
    render() {
        return (
            <div className="logos">
                <div className={styles['deutz-fahr']}>
                    <Link to="/products/premium/8/1">
                        <img src={deutz} alt="deutz-fahr" width="350" />
                    </Link>
                </div>
                <div className={styles['amazone']}>
                    <Link to="/products/premium/9/1">
                        <img src={amazone} alt="amazone" width="330" />
                    </Link>
                </div>
                <div className={styles['dfm']}>
                    <img src={dfm} alt="dfm" width="150" />
                </div>
                <div className={styles['lusna']}>
                    <Link to="/products/premium/24/1">
                        <img src={lusna} alt="lusna" width="176" />
                    </Link>
                </div>
                <div className={styles['sicma']}>
                    <Link to="/products/premium/11/1">
                        <img src={sicma} alt="sicma" width="213" height="90" />
                    </Link>
                </div>
                <div className={styles['sola']}>
                    <Link to="/products/premium/10/1">
                        <img src={sola} alt="sola" width="200" />
                    </Link>
                </div>
                <div className={styles['fantini']}>
                    <Link to="/products/premium/12/1">
                        <img src={fantini} alt="fantini" width="170" />
                    </Link>
                </div>
                <div className={styles['avmap']}>
                    <Link to="/products/premium/13/1">
                        <img src={avmap} alt="avmap" width="181" />
                    </Link>
                </div>
                <div className={styles['ozdoken']}>
                    <Link to="/products/premium/25/1">
                        <img src={ozdoken} alt="ozdoken" width="174" />
                    </Link>
                </div>
                <div className={styles['cicoria']}>
                    <Link to="/products/premium/14/1">
                        <img src={cicoria} alt="cicoria" width="219" />
                    </Link>
                </div>
                <div className={styles['quivogne']}>
                    <Link to="/products/premium/15/1">
                        <img src={quivogne} alt="quivogne" width="243" />
                    </Link>
                </div>
                <div className={styles['sloboda']}>
                    <img src={sloboda} alt="sloboda" width="242" />
                </div>
            </div>
        );
    }
}

export default Partners;
