import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import AliceCarousel from 'react-alice-carousel';
import { Row } from 'simple-flexbox';
import NewsItem from '../NewsItem';
import Api from '../../services';
// import fetchProducts from '../../fixtures/fetchNews';
import { NEWS_INDEX } from '../../constants';

import 'react-alice-carousel/lib/alice-carousel.css';

import './index.sass';

const responsive = {
    0: { items: 1 },
    1024: { items: 2 },
    1550: { items: 3 },
    2048: { items: 4 },
};

class NewsCarousel extends Component {
    state = {
        news: [],
        error: null,
    };
    componentDidMount() {
        const { locale } = this.props.intl;
        return Api.getItemsByCategories(1, NEWS_INDEX, locale)
            .then(res => this.setState({ news: res.data }))
            .catch(error => this.setState({ error }));
    }
    componentDidUpdate(prevProps) {
        const { locale } = this.props.intl;
        if (prevProps.intl.locale !== locale) {
            return Api.getItemsByCategories(1, NEWS_INDEX, locale)
                .then(res => this.setState({ news: res.data }))
                .catch(error => this.setState({ error }));
        }
    }
    renderItems = () => {
        const { news } = this.state;
        return news.map(item => {
            return (
                <NewsItem
                    key={item.id}
                    id={item.id}
                    excerpt={item.excerpt.rendered}
                    image={item.content.rendered}
                    title={item.title.rendered}
                    date={item.date.rendered}
                />
            );
        });
    };

    render() {
        const { news } = this.state;
        return (
            <div className="carousel-wrap">
                <button
                    className="prev"
                    onClick={() => this.Carousel._slidePrev()}
                />
                <Row horizontal="center" alignContent="center">
                    <AliceCarousel
                        autoPlay={news.length > 3}
                        autoPlayInterval={12000}
                        responsive={responsive}
                        mouseDragEnabled={news.length > 3}
                        items={this.renderItems()}
                        buttonsDisabled={true}
                        ref={el => (this.Carousel = el)}
                    />
                    <button
                        className="next"
                        onClick={() => this.Carousel._slideNext()}
                    />
                </Row>
            </div>
        );
    }
}

NewsCarousel.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(NewsCarousel);
