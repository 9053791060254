import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { parsImg } from '../../utils';

import './index.sass';

class NewsItem extends Component {
    renderHeadImg = news => {
        return (
            news && (
                <div
                    className="head-image"
                    dangerouslySetInnerHTML={{ __html: parsImg(news) }}
                />
            )
        );
    };
    render() {
        const { image, title, date, excerpt, id } = this.props;
        const day = moment(date).format('D MMMM YYYY');
        return (
            <div className="news-short">
                <div>
                    {this.renderHeadImg(image)}
                    <h3>
                        <Link to={`/news/${id}`}>{title}</Link>
                    </h3>
                    <h4>
                        <FontAwesomeIcon icon={faClock} />
                        <time dateTime={date}>{day}</time>
                    </h4>
                    <div
                        className="excerpt"
                        dangerouslySetInnerHTML={{ __html: excerpt }}
                    />
                </div>
                <div>
                    <Link to={`/news/${id}`}>
                        <div className="read-more">
                            <FormattedMessage
                                id="Common.readMore"
                                defaultMessage="READ MORE"
                            />
                        </div>
                    </Link>
                </div>
            </div>
        );
    }
}

NewsItem.propTypes = {
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string,
    excerpt: PropTypes.string.isRequired,
};

export default NewsItem;
