import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './index.module.sass';

export default function Address({ address }) {
    return (
        <div className={styles['address']}>
            <h3>
                <FormattedMessage
                    id="Address.address"
                    defaultMessage="address"
                />
            </h3>
            <p className={styles['city-name']}>
                <FormattedMessage id="Address.city" defaultMessage="city" />
                {address.city}
            </p>
            <p className={styles['street']}>
                {address.street ? (
                    <>
                        <FormattedMessage
                            id="Address.street"
                            defaultMessage="street"
                        />
                        {address.street}
                    </>
                ) : (
                    address.other
                )}
            </p>
            <p className={styles['telephone']}>
                <FormattedMessage id="Address.tel" defaultMessage="tel" />
                <a href={address.telHref}>{address.tel}</a>
            </p>
            <p className={styles['mobile']}>
                <FormattedMessage id="Address.mobile" defaultMessage="mobile" />
                <a href={address.mobileHref}>{address.mobile}</a>
            </p>
            <p className={styles['manager']}>
                <FormattedMessage
                    id="Address.manager"
                    defaultMessage="manager"
                />
                {address.manager}
            </p>
        </div>
    );
}
