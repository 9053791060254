import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { delay, waitFor } from '../../utils';
import fill from 'lodash.fill';

const percents = fill(Array(101), null);

class Percents extends Component {
    state = {
        count: 0,
        error: null,
    };

    componentDidMount() {
        if (this.props.turnON) {
            delay(percents, this.setCount).catch(error =>
                this.setState({ error })
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.turnON && prevProps.turnON !== this.props.turnON) {
            delay(percents, this.setCount).catch(error =>
                this.setState({ error })
            );
        }
        if (this.state.count === 101) {
            this.props.preloaderOver();
        }
    }

    setCount = async () => {
        await waitFor(10);
        this.setState({ count: this.state.count + 1 });
    };

    render() {
        return <span>{this.state.count}%</span>;
    }
}

Percents.propTypes = {
    turnON: PropTypes.bool,
    preloaderOver: PropTypes.func,
};

export default Percents;
