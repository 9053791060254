import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './index.sass';
import Line from '../../components/Line';
import SectionTitle from '../../components/SectionTitle';
import image1 from './img/1-min.jpg';
import image2 from './img/2-min.png';
import image3 from './img/3-min.jpg';
import image4 from './img/4-min.jpg';
import image5 from './img/5-min.jpg';
import servicesHeader from './img/headerServices.jpg';

import reparationImg1 from '../Reparation/img/reapar1.jpg';
import reparationImg2 from '../Reparation/img/repar2.jpg';
import reparationImg3 from '../Reparation/img/repar3.jpg';

const isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

class ServicesPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className="agromotor-service-wrapper" id="service-top-section">
                <div className="service-center">
                    <div className="agromotor-service">
                        <img
                            src={servicesHeader}
                            alt=""
                            className="background-photo-services"
                        />
                    </div>
                    <div id={isChrome ? 'cservice-1' : 'service-1'} />
                    <div className="service-section-1">
                        <Line color={this.props.colorType} />
                        <SectionTitle
                            title="Reparation.servicesPremium"
                            colorType="orange"
                        />
                        <div className="service-center">
                            <div className="premium-section">
                                <div className="premium-column-images">
                                    <img
                                        src={image1}
                                        alt=""
                                        className="image-1"
                                    />
                                    <img
                                        src={image2}
                                        alt=""
                                        className="image-2"
                                    />
                                </div>
                                <p className="service-stories premium-text">
                                    <FormattedMessage
                                        id="Services.section1"
                                        defaultMessage="Lorem ipsum dolor sit amet consectetur adipisicing
                                                elit. Consequuntur, hic recusandae! Voluptatibus ea
                                                voluptatem et hic quo saepe, cumque ad recusandae
                                                atque veritatis quasi amet modi mollitia eligendi
                                                dolores tempore, odit molestiae doloremque iure
                                                libero doloribus sequi culpa quis. Facilis id vitae
                                                dolorum sequi adipisci.
                                                Dignissimos ducimus nisi voluptate laudantium
                                                sint natus excepturi ab voluptatibus consectetur
                                                quidem impedit animi alias exercitationem laboriosam
                                                deleniti maxime cum porro placeat, perspiciatis
                                                aperiam, dolore quas quaerat rerum.
                                                Ipsam, excepturi commodi accusamus maiores
                                                repellendus consequatur impedit cupiditate sapiente
                                                dolores dolor dignissimos, expedita ullam hic
                                                delectus odio labore ut amet ea pariatur ad."
                                    />
                                </p>
                                <img src={image3} alt="" className="image-3" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-section-2">
                    <Line color={this.props.colorType} />
                    <SectionTitle
                        title="Reparation.servicesStandard"
                        colorType="orange"
                    />
                    <div className="service-center">
                        <div className="econom-section">
                            <div className="econom-firstblock">
                                <img src={image4} alt="" className="image-4" />
                                <p className="service-stories econom-text1">
                                    <FormattedMessage
                                        id="Services.section2"
                                        defaultMessage="Lorem ipsum dolor sit amet consectetur
                                            adipisicing elit. Consequuntur, hic recusandae!
                                            Voluptatibus ea voluptatem et hic quo saepe,
                                            cumque ad recusandae atque veritatis. Ipsam, excepturi commodi accusamus maiores
                                            repellendus consequatur impedit cupiditate
                                            sapiente dolores dolor dignissimos, expedita
                                            ullam hic delectus odio labore ut amet ea
                                            pariatur ad."
                                    />
                                </p>
                            </div>
                            <div className="econom-secondblock">
                                <p className="service-stories econom-text2">
                                    <FormattedMessage
                                        id="Services.section3"
                                        defaultMessage="Dignissimos ducimus nisi voluptate laudantium
                                            sint natus excepturi ab voluptatibus consectetur
                                            quidem impedit animi alias exercitationem
                                            laboriosam deleniti maxime cum porro placeat,
                                            perspiciatis aperiam, dolore quas quaerat rerum.
                                            Ipsam, excepturi commodi accusamus maiores
                                            repellendus consequatur impedit cupiditate
                                            sapiente dolores dolor dignissimos, expedita
                                            ullam hic delectus odio labore ut amet ea
                                            pariatur ad."
                                    />
                                </p>
                                <img src={image5} alt="" className="image-5" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-section-3">
                    <Line color={this.props.colorType} />
                    <SectionTitle
                        title="Reparation.servicesMulti"
                        colorType="orange"
                    />
                    <div className="reparation-description">
                        <p className="text-reparation">
                            <FormattedMessage
                                id="Reparation.description1"
                                defaultMessage="Compania Agromotor fiind în strînsă legătură cu fermierii țări."
                            />
                        </p>
                    </div>
                    <div className="reparation-gallery">
                        <img
                            src={reparationImg1}
                            alt=""
                            className="reparation-photo1"
                        />
                        <img
                            src={reparationImg2}
                            alt=""
                            className="reparation-photo2"
                        />
                        <img
                            src={reparationImg3}
                            alt=""
                            className="reparation-photo3"
                        />
                    </div>
                    <div className="reparation-description">
                        <p className="text-reparation">
                            <FormattedMessage
                                id="Reparation.description2"
                                defaultMessage="Un Service Multibrand este o necesitate a fermierului, pe care AGROMOTOR SERVICE o poate asigura."
                            />
                        </p>
                        <ul className="text-reparation reparation-list">
                            <li>
                                <FormattedMessage
                                    id="Reparation.list1"
                                    defaultMessage="Diagnostic electronic complex,"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="Reparation.list2"
                                    defaultMessage="Reparații capitale,"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="Reparation.list3"
                                    defaultMessage="Reparații motoare,"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="Reparation.list4"
                                    defaultMessage="Reparații cutii de viteză,"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="Reparation.list5"
                                    defaultMessage="Reparații punți față/spate,"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="Reparation.list6"
                                    defaultMessage="Reparații sisteme electrice,"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="Reparation.list7"
                                    defaultMessage="Reparații sisteme hidraulice,"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="Reparation.list8"
                                    defaultMessage="Verificare, reparare sisteme de alimentare,"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="Reparation.list9"
                                    defaultMessage="Reparăm reductoare, servomecanisme, regulatoare, pompe hidraulice, distribuitoare,"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="Reparation.list10"
                                    defaultMessage="Verificări, lucrări de strungărie,"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="Reparation.list11"
                                    defaultMessage="Alimentarea condiționerelor,"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="Reparation.list12"
                                    defaultMessage="Reglarea, repararea balotierelor etc."
                                />
                            </li>
                        </ul>
                        <p className="text-reparation welcome-reparation">
                            <FormattedMessage
                                id="Reparation.guarantees"
                                defaultMessage="Oferim garantie, calitate si operativitate."
                            />
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

ServicesPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            section: PropTypes.string,
        }),
    }),
    colorType: PropTypes.string,
};

export default ServicesPage;
