/**
 * @constant
 * @type {string}
 */
export const ORANGE = 'orange';
/**
 * @constant
 * @type {string}
 */
export const WHITE = 'white';
/**
 * @constant
 * @type {string}
 */
export const GREEN = 'green';
/**
 * @constant
 * @type {string}
 */
export const BROWN = 'brown';
/**
 * @constant
 * @type {string}
 */
export const GRAY = 'gray';

/**
 * @constant
 * @type {string}
 */
export const WHITE_HEX = '#ffffff';
/**
 * @constant
 * @type {string}
 */
export const GREEN_HEX = '#5ebd57';
/**
 * @constant
 * @type {string}
 */
export const BLACK_HEX = '#000000';
