import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NavHashLink as NavLink } from 'react-router-hash-link';

import styles from './index.module.sass';

class ProductsList extends Component {
    state = {
        isPremium: false,
    };

    setPremium = () => {
        this.setState({ isPremium: !this.state.isPremium });
    };

    render() {
        return (
            <div
                className={styles['products-menu']}
                onClick={this.props.onClick}
            >
                <div className={styles['rule-on-top']} />
                <div className={styles['triangle']} />
                <div className={styles['premium']}>
                    <ul>
                        <li>
                            <NavLink
                                smooth
                                to="/products/premium/"
                                style={{ color: '#5ebd57' }}
                                onClick={this.setPremium}
                            >
                                <FormattedMessage
                                    id="Cards.premium"
                                    defaultMessage="Premium"
                                />
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                smooth
                                to="/products/standard/"
                                style={{ color: '#f5871f' }}
                                onClick={this.setPremium}
                            >
                                <FormattedMessage
                                    id="Cards.standard"
                                    defaultMessage="Premium"
                                />
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                smooth
                                to="/parts"
                                style={{ color: '#515151' }}
                            >
                                <FormattedMessage
                                    id="Services.parts"
                                    defaultMessage="Premium"
                                />
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                smooth
                                to="/reparation"
                                style={{ color: '#2455A2' }}
                            >
                                <FormattedMessage
                                    id="Services.repairs"
                                    defaultMessage="Premium"
                                />
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

ProductsList.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default ProductsList;
