import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BRANDS, TYPES, PREMIUM_INDEX } from '../../constants/index';
import { connect } from 'react-redux';
import {
    setFilterBrands,
    getFilterBrands,
    setFilterTypes,
    getFilterTypes,
} from '../../ducks/products';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import cx from 'classnames';

import styles from './index.module.sass';

const style = {
    root: {
        color: '#ff8718',
        '&$checked': {
            color: '#ff8718',
        },
    },
    checked: {},
    label: {
        textTransform: 'uppercase',
        fontFamily: 'Montserrat',
        fontWeight: '500',
    },
};

class FilterMenu extends Component {
    state = {
        brands: false,
        types: false,
        showFilter: true,
    };

    handleClick = param => {
        this.setState({ [param]: !this.state[param] });
    };

    onFilterBrand = e => {
        const locale = this.props.intl.locale;
        this.props.setFilterBrands(e.target.value, locale, PREMIUM_INDEX);
    };
    onFilterType = e => {
        const locale = this.props.intl.locale;
        this.props.setFilterTypes(e.target.value, locale, PREMIUM_INDEX);
    };

    filterToggler = () => {
        this.setState({ showFilter: !this.state.showFilter });
    };

    renderFirstFiveClassicBrands = () => {
        const { classes, filterBrands } = this.props;
        return Object.entries(BRANDS)
            .slice(14, 19)
            .map(brand => (
                <li key={brand[0]}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={brand[0]}
                                onChange={this.onFilterBrand}
                                id="supertyp"
                                classes={{
                                    root: classes.root,
                                    checked: classes.checked,
                                }}
                                checked={filterBrands.includes(brand[0])}
                            />
                        }
                        label={brand[1]}
                        classes={{
                            label: classes.label,
                        }}
                    />
                </li>
            ));
    };
    renderRestOfClassicBrands = () => {
        const { classes, filterBrands } = this.props;
        return Object.entries(BRANDS)
            .slice(19)
            .map(brand => (
                <li key={brand[0]}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={brand[0]}
                                onChange={this.onFilterBrand}
                                id="supertyp"
                                classes={{
                                    root: classes.root,
                                    checked: classes.checked,
                                }}
                                checked={filterBrands.includes(brand[0])}
                            />
                        }
                        label={brand[1]}
                        classes={{
                            label: classes.label,
                        }}
                    />
                </li>
            ));
    };
    renderFirstFiveTypes = () => {
        const { classes, filterTypes } = this.props;
        return Object.entries(TYPES)
            .slice(0, 4)
            .map(type => (
                <li key={type[0]}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={type[0]}
                                onChange={this.onFilterType}
                                id="supertyp"
                                classes={{
                                    root: classes.root,
                                    checked: classes.checked,
                                }}
                                checked={filterTypes.includes(type[0])}
                            />
                        }
                        label={type[1]}
                        classes={{
                            label: classes.label,
                        }}
                    />
                </li>
            ));
    };
    renderRestOfTypes = () => {
        const { classes, filterTypes } = this.props;
        return Object.entries(TYPES)
            .slice(4)
            .map(type => (
                <li key={type[0]}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={type[0]}
                                onChange={this.onFilterType}
                                id="supertyp"
                                classes={{
                                    root: classes.root,
                                    checked: classes.checked,
                                }}
                                checked={filterTypes.includes(type[0])}
                            />
                        }
                        label={type[1]}
                        classes={{
                            label: classes.label,
                        }}
                    />
                </li>
            ));
    };
    render() {
        const { showFilter } = this.state;
        return (
            <div
                className={cx({
                    [styles['filter-container']]: true,
                    [styles['open']]: this.state.showFilter,
                    [styles['closed']]: !this.state.showFilter,
                })}
            >
                <button
                    onClick={this.filterToggler}
                    className={styles['classic-hide-filter']}
                >
                    <div
                        className={styles['icon']}
                        style={
                            showFilter ? null : { transform: 'rotate(180deg)' }
                        }
                    />
                </button>
                <div className={styles['filter-menu']}>
                    <ul>
                        <div style={{ marginBottom: '20px' }}>
                            <div className={styles['classic-list-heading']}>
                                <FormattedMessage
                                    id="Classic.brands"
                                    defaultMessage="Standard Brands"
                                />
                            </div>
                            <div className="first-five-brands">
                                {this.renderFirstFiveClassicBrands()}
                            </div>
                            {this.renderRestOfClassicBrands()}
                        </div>
                        <div className="types-list">
                            <div className={styles['classic-list-heading']}>
                                <FormattedMessage
                                    id="Classic.types"
                                    defaultMessage="Standard Types"
                                />
                            </div>
                            <div className="first-five-brands">
                                {this.renderFirstFiveTypes()}
                            </div>
                            {this.renderRestOfTypes()}
                        </div>
                    </ul>
                </div>
            </div>
        );
    }
}

FilterMenu.propTypes = {
    setFilterBrands: PropTypes.func.isRequired,
    setFilterTypes: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    classes: PropTypes.object,
    filterBrands: PropTypes.arrayOf(PropTypes.string),
    filterTypes: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => {
    return {
        filterBrands: getFilterBrands(state),
        filterTypes: getFilterTypes(state),
    };
};

const mapDispatchToProps = {
    setFilterBrands,
    setFilterTypes,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(withStyles(style)(FilterMenu)));
