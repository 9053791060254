import React, { Component, Fragment } from 'react';
import { addLocaleData, IntlProvider } from 'react-intl';
import { HashRouter } from 'react-router-dom';
import en from 'react-intl/locale-data/en';
import ru from 'react-intl/locale-data/ru';
import ro from 'react-intl/locale-data/ro';
import { RO, EN, RU } from './constants';
import localeDataEn from './messages/en.json';
import localeDataRo from './messages/ro.json';
import localeDataRu from './messages/ru.json';
import Header from './components/Header';
import Pages from './pages';
import Footer from './components/Footer';
import Preloader from './components/Preloader';
import { getDefaultLang } from './utils';

addLocaleData([...en, ...ru, ...ro]);

/**
 * React component.
 * Main component.
 * @class App
 */
class App extends Component {
    /**
     * Get User Language.
     * @public
     * @function componentDidMount
     */
    componentDidMount() {
        this.setLanguage(localStorage.getItem('locale') || getDefaultLang());
    }

    /**
     * state
     * @type {{loaded: boolean, messages: {}, locale: string}}
     */
    state = {
        messages: {},
        // todo move local 'locale' variable to redux state
        locale: RO,
        loaded: false,
    };

    /**
     * Set loaded true
     *
     * @function preloaderEnd
     */
    preloaderEnd = () => {
        this.setState({ loaded: true });
    };

    /**
     * Set Language to state
     * @function setLanguage
     * @param locale
     */
    setLanguage = locale => {
        let messages;
        switch (locale) {
            case EN:
                messages = localeDataEn;
                break;
            case RU:
                messages = localeDataRu;
                break;
            case RO:
            default:
                messages = localeDataRo;
        }
        this.setState({ messages, locale }, () => {
            localStorage.setItem('locale', this.state.locale);
        });
    };

    /**
     * @public
     * @returns {*}
     */
    render() {
        const { messages, locale } = this.state;
        return (
            <Fragment>
                {this.state.loaded ? (
                    <IntlProvider
                        locale={locale}
                        messages={messages}
                        defaultLocale={RO}
                    >
                        <HashRouter>
                            <Fragment>
                                <Header
                                    setLanguage={this.setLanguage}
                                    locale={locale}
                                />
                                <Pages />
                                <Footer />
                            </Fragment>
                        </HashRouter>
                    </IntlProvider>
                ) : (
                    <Preloader preloaderOver={this.preloaderEnd} />
                )}
            </Fragment>
        );
    }
}

export default App;
