export default [
    {
        id: 1,
        name: 'Chișinău',
        address: {
            city: 'Chișinău',
            street: ' Muncești 426 A',
            tel: ': 022-50-50-27',
            telHref: 'tel:+373-22-505-027',
            mobile: ': 069-698-253',
            mobileHref: 'tel:+373-69-899-399',
            manager: ': Valeriu Potorac',
        },
        position: {
            top: '-137px',
            left: '190px',
        },
    },
    {
        id: 2,
        name: 'Ialoveni',
        address: {
            city: 'Ialoveni',
            street: ' Şoseaua Hîncești 35',
            tel: ': 022-01-10-03',
            telHref: 'tel:+373-22-011-003',
            mobile: ': 069-150-696',
            mobileHref: 'tel:+373-62-162-505',
            manager: ': Andrei Josan',
        },
        position: {
            top: '-138px',
            left: '183px',
        },
    },
    {
        id: 3,
        name: 'Orhei',
        address: {
            city: 'Orhei',
            other: 'Satul Pelivan',
            tel: ': 023-56-86-52',
            telHref: 'tel:+373-23-568-652',
            mobile: ': 069-407-797',
            mobileHref: 'tel:+373-69-407-797',
            manager: ': Pavel Savitschi',
        },
        position: {
            top: '-197px',
            left: '187px',
        },
    },
    {
        id: 4,
        name: 'Bălți',
        address: {
            city: 'Bălți',
            street: ' Dragomir 4',
            tel: ': 023-14-29-07',
            telHref: 'tel:+373-23-142-907',
            mobile: ': 068-949-449',
            mobileHref: 'tel:+373-68-949-499',
            manager: ': Ion Negrescu',
        },
        position: {
            top: '-260px',
            left: '108px',
        },
    },
    {
        id: 5,
        name: 'Edineț',
        address: {
            city: 'Edineț',
            street: ' Mihai Eminescu 9',
            tel: ': 024-62-29-26',
            telHref: 'tel:+373-24-622-926',
            mobile: ': 068-600-828',
            mobileHref: 'tel:+373-68-600-828',
            manager: ': Vladimir Roibu',
        },
        position: {
            top: '-327px',
            left: '56px',
        },
    },
    {
        id: 6,
        name: 'Rezina',
        address: {
            city: 'Rezina',
            street: ' Lomonosov 141',
            tel: ': 025-42-21-44',
            telHref: 'tel:+373-25-422-144',
            mobile: ': 060-081-888',
            mobileHref: 'tel:+373-60-081-888',
            manager: ': Vladimir Graur',
        },
        position: {
            top: '-289px',
            left: '200px',
        },
    },
    {
        id: 7,
        name: 'Ştefan Vodă',
        address: {
            city: 'Ştefan Vodă',
            street: ' Zona Industrială 1',
            tel: ': 024-22-22-32',
            telHref: 'tel:+373-24-222-232',
            mobile: ': 060-574-742',
            mobileHref: 'tel:+373-60-574-742',
            manager: ': Vasile Negrescu',
        },
        position: {
            top: '-140px',
            left: '262px',
        },
    },
    {
        id: 8,
        name: 'Făleşti',
        address: {
            city: 'Făleşti',
            street: ' Ştefan cel Mare 81',
            tel: ': 025-92-35-08',
            telHref: 'tel:+373-25-923-508',
            mobile: ': 068-531-853',
            mobileHref: 'tel:+373-68-531-853',
            manager: ': Grigore Avram',
        },
        position: {
            top: '-292px',
            left: '89px',
        },
    },
    {
        id: 9,
        name: 'Florești',
        address: {
            city: 'Florești',
            other: 'Satul Gura Camencii',
            tel: ': 025-09-22-50',
            telHref: 'tel:+373-25-092-250',
            mobile: ': 068-037-744',
            mobileHref: 'tel:+373-68-037-744',
            manager: ': Anatol Arhirii',
        },
        position: {
            top: '-347px',
            left: '142px',
        },
    },
    {
        id: 10,
        name: 'Străşeni',
        address: {
            city: 'Străşeni',
            street: ' Morilor',
            tel: ': 023-72-10-05',
            telHref: 'tel:+373-23-721-005',
            mobile: ': 068-421-821',
            mobileHref: 'tel:+373-68-421-821',
            manager: ': Serghei Donoagă',
        },
        position: {
            top: '-267px',
            left: '167px',
        },
    },
    {
        id: 11,
        name: 'Cimișlia',
        address: {
            city: 'Cimișlia',
            street: ' Nicolae Iorga',
            tel: ': 024-16-80-30',
            telHref: 'tel:+373-24-168-030',
            mobile: ': 060-453-030',
            mobileHref: 'tel:+373-60-453-030',
            manager: ': Artem Reva',
        },
        position: {
            top: '-207px',
            left: '185px',
        },
    },
    {
        id: 12,
        name: 'Hîncești',
        address: {
            city: 'Hîncești',
            street: ' Mihalcea Hîncu 137',
            tel: ': 026-96-80-50',
            telHref: 'tel:+373-26-968-050',
            mobile: ': 069-770-703',
            mobileHref: 'tel:+373-69-770-703',
            manager: ': Gheorghe Talmaci',
        },
        position: {
            top: '-259px',
            left: '169px',
        },
    },
    {
        id: 13,
        name: 'Cahul',
        address: {
            city: 'Cahul',
            other: 'Satul Roșu',
            tel: ': 029-96-50-75',
            telHref: 'tel:+373-29-965-075',
            mobile: ': 060-540-405',
            mobileHref: 'tel:+373-60-540-405',
            manager: ': Andrei Dobrin',
        },
        position: {
            top: '-157px',
            left: '137px',
        },
    },
];
