import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import fill from 'lodash.fill';
import classNames from 'classnames';
import { getTotalPages, setPage, getPage } from '../../ducks/products';
import { injectIntl, intlShape } from 'react-intl';
import { PREMIUM, CLASSIC } from '../../constants';

import './index.sass';

class Paginator extends Component {
    renderPagesLinks = pages => {
        const { page, category } = this.props;
        return pages.map((_, i) => (
            <button
                className={classNames({
                    'active-page': +page === i + 1,
                    'active-page-premium': category === PREMIUM,
                    'active-page-classic': category === CLASSIC,
                })}
                onClick={() => this.props.setPage(i + 1)}
                key={i}
            >
                <span className="test-page-in-paginator" />
                {i + 1}
            </button>
        ));
    };
    renderPreviously = () => {
        const { page } = this.props;
        if (+page - 1 < 1) return null;
        return (
            <button onClick={() => this.props.setPage(+page - 1)}>
                &laquo;
            </button>
        );
    };
    renderNext = () => {
        const { page, totalPages } = this.props;
        if (+page + 1 > totalPages) return null;
        return (
            <button onClick={() => this.props.setPage(+page + 1)}>
                &raquo;
            </button>
        );
    };

    render() {
        const { totalPages } = this.props;
        return (
            <div className="pagination">
                {this.renderPreviously()}
                {this.renderPagesLinks(fill(Array(totalPages), null))}
                {this.renderNext()}
            </div>
        );
    }
}

Paginator.propTypes = {
    page: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    category: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
    return {
        totalPages: getTotalPages(state),
        page: getPage(state),
    };
};

const mapDispatchToProps = {
    setPage,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(Paginator));
