import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getTotalPages,
    productsList,
    fetchAll,
    excludeCategory,
} from '../../ducks/products';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Column, Row } from 'simple-flexbox';
import ProductExcerpt from '../../components/ProductExcerpt';
import Paginator from '../../components/Paginator';
import Premium from '../../components/FilterMenu/premium';
import Classic from '../../components/FilterMenu/classic';
import Switcher from '../../components/Switcher';
import { CATEGORIES } from '../../constants';

import './index.sass';

class Products extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        const { category } = this.props.match.params;
        const { exclCategory } = this.props;
        if (category === CATEGORIES[exclCategory] || exclCategory === 0) {
            this.props.fetchAll(category, this.props.intl.locale);
        }
    }

    componentDidUpdate(prevProps) {
        const { category } = this.props.match.params;
        if (prevProps.match.params !== this.props.match.params) {
            this.props.fetchAll(category, this.props.intl.locale);
        }
    }

    renderProducts = () => {
        const { products } = this.props;
        return products.map(prod => {
            return <ProductExcerpt key={prod.id} product={prod} />;
        });
    };

    render() {
        const { category } = this.props.match.params;
        return (
            <div className="products-wrapper">
                {category === 'premium' ? <Premium /> : <Classic />}
                <Switcher category={category} />
                <Column horizontal="center" className="products-brand">
                    <Row wrap horizontal="center">
                        {this.renderProducts()}
                    </Row>
                    {this.props.totalPages > 1 && (
                        <Paginator category={category} />
                    )}
                </Column>
                <div className="phonenumber">
                    {category === 'premium' ? (
                        <div className="lux">
                            <span>Telefon de contact:</span>
                            <span>0621 62 505</span>
                        </div>
                    ) : (
                        <div className="budget">
                            <span>Telefon de contact:</span>
                            <span>0698 27 415</span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

Products.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            category: PropTypes.string,
        }),
    }),
    intl: intlShape.isRequired,
    totalPages: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    products: PropTypes.arrayOf(PropTypes.shape({})),
    fetchAll: PropTypes.func.isRequired,
    exclCategory: PropTypes.number,
};

const mapStateToProps = state => {
    return {
        totalPages: getTotalPages(state),
        products: productsList(state),
        exclCategory: excludeCategory(state),
    };
};

const mapDispatchToProps = {
    fetchAll,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(Products));
