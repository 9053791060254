import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './index.sass';

class SectionTitle extends Component {
    render() {
        return (
            <div className="wrap">
                <FormattedMessage
                    id={`${this.props.title}`}
                    defaultMessage={this.props.defaultMessage}
                />
            </div>
        );
    }
}

SectionTitle.defaultProps = {
    defaultMessage: 'No Translation',
};

SectionTitle.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    defaultMessage: PropTypes.string,
};

export default SectionTitle;
