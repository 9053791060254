import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from '../history';
import productsReducer, {
    moduleName as productsModule,
} from '../ducks/products';

export default combineReducers({
    [productsModule]: productsReducer,
    router: connectRouter(history),
});
