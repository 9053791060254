import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import Products from './Products';
import Product from './Product';
import Homepage from './Homepage';
import NewsPage from './News';
import ServicesPage from './Services';
import AboutUs from './AboutUs';
import Parts from './Parts';
import Reparation from './Reparation';

class Pages extends Component {
    render() {
        return (
            <Fragment>
                <Switch>
                    <Route path="/services" component={ServicesPage} />
                    <Route path="/products/:category/" component={Products} />
                    <Route path="/news/:id" component={NewsPage} />
                    <Route path="/product/:id" component={Product} />
                    <Route exact path="/parts" component={Parts} />
                    <Route exact path="/reparation" component={Reparation} />
                    <Route path="/about-us" component={AboutUs} />
                    <Route exact path="/" component={Homepage} />
                </Switch>
            </Fragment>
        );
    }
}

export default Pages;
