import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './index.sass';
import Line from '../../components/Line';
import SectionTitle from '../../components/SectionTitle';
import img1 from './img/about-1.jpg';
import img2 from './img/about-2.jpg';
import img3 from './img/about-3.jpg';
import img4 from './img/about-4.jpg';
import img5 from './img/about-5.jpg';
import historyHeader from './img/headerHistory.jpg';
import deutz from '../../pages/Homepage/img/logos/deutz-fahr.svg';
import amazone from '../../pages/Homepage/img/logos/amazone.png';
import sicma from '../../pages/Homepage/img/logos/sicma.png';
import sola from '../../pages/Homepage/img/logos/sola.png';
import fantini from '../../pages/Homepage/img/logos/fantini.png';
import avmap from '../../pages/Homepage/img/logos/avmap.png';
import cicoria from '../../pages/Homepage/img/logos/cicoria.png';
import quivogne from '../../pages/Homepage/img/logos/quivogne.png';
import styles from '../../components/Partners/index.module.sass';
import { HashLink as Link } from 'react-router-hash-link';

class AboutUs extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="agromotor-history-wrapper" id="history-top-section">
                <div className="agromotor-history">
                    <img
                        src={historyHeader}
                        alt=""
                        className="background-photo-history"
                    />
                </div>
                <div className="history-1">
                    <Line colorType={this.props.colorType} />
                    <SectionTitle
                        title="About.historyTitle"
                        colorType="orange"
                    />
                    <div className="about-history">
                        <p className="text-stories story-1">
                            <FormattedMessage
                                id="About.history"
                                defaultMessage="Lorem ipsum dolor sit amet consectetur adipisicing elit."
                            />
                        </p>
                        <img src={img1} alt="" className="img-1" />
                    </div>
                </div>
                <div className="history-2">
                    <Line colorType={this.props.colorType} />
                    <SectionTitle title="About.text1" colorType="orange" />
                    <div className="about-history">
                        <div className="article">
                            <img src={img2} alt="" className="img-2" />
                            <p className="text-stories story-2">
                                <FormattedMessage
                                    id="About.historyStart"
                                    defaultMessage="Lorem ipsum dolor sit amet consectetur adipisicing elit."
                                />
                            </p>
                        </div>
                        <div className="article article-reverse">
                            <p className="text-stories story-4">
                                <FormattedMessage
                                    id="About.historyEnd"
                                    defaultMessage="Lorem ipsum dolor sit amet consectetur adipisicing elit."
                                />
                            </p>
                            <img src={img3} alt="" className="img-3" />
                        </div>
                        <div className="article">
                            <img src={img4} alt="" className="img-4" />
                            <p className="text-stories story-3">
                                <FormattedMessage
                                    id="About.historyMiddle"
                                    defaultMessage="Lorem ipsum dolor sit amet consectetur adipisicing elit."
                                />
                            </p>
                        </div>
                    </div>
                    <div className="services-list">
                        <p>
                            <FormattedMessage
                                id="About.listIntro"
                                defaultMessage="Activities"
                            />
                        </p>
                        <ul>
                            <li>
                                <FormattedMessage
                                    id="About.list-1"
                                    defaultMessage="Activities"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="About.list-2"
                                    defaultMessage="Activities"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="About.list-3"
                                    defaultMessage="Activities"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="About.list-4"
                                    defaultMessage="Activities"
                                />
                            </li>
                        </ul>
                    </div>
                    <div className="services-list2">
                        <p>
                            <FormattedMessage
                                id="About.listEnd"
                                defaultMessage="Lorem ipsum dolor sit amet consectetur adipisicing elit."
                            />
                        </p>
                    </div>
                    <div className="history-3">
                        <Line colorType={this.props.colorType} />
                        <SectionTitle title="About.text2" colorType="orange" />
                        <div className="premium-partners">
                            <div className="exclusive">
                                <div className={styles['deutz-fahr']}>
                                    <Link to="/products/premium/8/1">
                                        <img
                                            src={deutz}
                                            alt="deutz-fahr"
                                            width="350"
                                        />
                                    </Link>
                                </div>
                                <div className={styles['amazone']}>
                                    <Link to="/products/premium/9/1">
                                        <img
                                            src={amazone}
                                            alt="amazone"
                                            width="330"
                                        />
                                    </Link>
                                </div>
                                <div className={styles['sola']}>
                                    <Link to="/products/premium/10/1">
                                        <img
                                            src={sola}
                                            alt="sola"
                                            width="200"
                                        />
                                    </Link>
                                </div>
                                <div className={styles['sicma']}>
                                    <Link to="/products/premium/11/1">
                                        <img
                                            src={sicma}
                                            alt="sicma"
                                            width="213"
                                            height="90"
                                        />
                                    </Link>
                                </div>
                                <div className={styles['fantini']}>
                                    <Link to="/products/premium/12/1">
                                        <img
                                            src={fantini}
                                            alt="fantini"
                                            width="170"
                                        />
                                    </Link>
                                </div>
                                <div className={styles['avmap']}>
                                    <Link to="/products/premium/13/1">
                                        <img
                                            src={avmap}
                                            alt="avmap"
                                            width="181"
                                        />
                                    </Link>
                                </div>
                                <div className={styles['cicoria']}>
                                    <Link to="/products/premium/14/1">
                                        <img
                                            src={cicoria}
                                            alt="cicoria"
                                            width="219"
                                        />
                                    </Link>
                                </div>
                                <div className={styles['quivogne']}>
                                    <Link to="/products/premium/15/1">
                                        <img
                                            src={quivogne}
                                            alt="quivogne"
                                            width="243"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="contain">
                            <div className="about-exclusive">
                                <img src={img5} alt="" className="img-5" />
                                <p className="text-stories story-5">
                                    <FormattedMessage
                                        id="About.historyFinal"
                                        defaultMessage="Lorem ipsum dolor sit amet consectetur adipisicing elit."
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AboutUs.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            section: PropTypes.string,
        }),
    }),
    colorType: PropTypes.string,
};

export default AboutUs;
